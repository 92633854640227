import {Alert, AlertSeverity, Button, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentMobileDirection, FlexContentSpacing, Icon, ModalContent, ModalNew, ModalNewSize, Tag, TagStyle} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {ConfigurationModeInformation} from "../../utils/ConfigurationModeInformation";
import {ConfigurationModeName} from "../../utils/ConfigurationMode";
import {TranslationPortalFile} from "../../utils/constants";

type ModalConfigModeProps = {
    active: boolean;
    modes: ConfigurationModeName[];
    onClose: () => void;
    onClick: (mode: ConfigurationModeName) => void;
}

const ModalConfigMode: FunctionComponent<ModalConfigModeProps> = ({active, modes, onClose, onClick}) => {
    const {t: textConfigurationMode} = useTranslation(TranslationPortalFile.CONFIGURATION_MODE);

    const CONFIGURATION_MODE_INFORMATIONS: {mode: ConfigurationModeName; info: ConfigurationModeInformation}[] = modes.map((mode) => {
        return {mode, info: textConfigurationMode(`modes.${mode}`, {returnObjects: true}) as ConfigurationModeInformation};
    });

    return (
        <ModalNew onClose={onClose} active={active} size={ModalNewSize.WIDTH_AUTO} keepMounted>
            <ModalContent>
                <FlexContent
                    direction={FlexContentDirection.ROW}
                    mobileDirection={FlexContentMobileDirection.COLUMN}
                    spacing={FlexContentSpacing.LARGE}
                    cssClass="modal-create-config"
                >
                    {CONFIGURATION_MODE_INFORMATIONS.map(({mode, info}) =>
                        <FlexContent
                            key={mode}
                            direction={FlexContentDirection.COLUMN}
                            spacing={FlexContentSpacing.MEDIUM}
                            justify={FlexContentJustify.SPACE_BETWEEN}
                            alignment={FlexContentAlignment.START}
                            cssClass="modal-create-config__mode"
                        >
                            <FlexContent
                                direction={FlexContentDirection.COLUMN}
                                spacing={FlexContentSpacing.SMALL}
                                alignment={FlexContentAlignment.START}
                                cssClass="modal-create-config__mode__content"
                            >
                                <Tag
                                    label={info.time}
                                    style={TagStyle.DEFAULT_CYAN_LIGHT}
                                    customColor={{borderColor: Color.CYAN_LIGHT}}
                                    icon={{name: "timer", outlined: true}}
                                />
                                <div className="modal-create-config__mode__content__title">{info.title}</div>
                                <p>{info.description}</p>
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                    {info.steps.map((step) =>
                                        <div key={step} className="modal-create-config__mode__content__steps__item">
                                            <Icon name="east" cssClass="modal-create-config__mode__content__steps__item__icon"/>
                                            <span>{step}</span>
                                        </div>
                                    )}
                                </FlexContent>
                                {info.indication &&
                                    <Alert
                                        severity={AlertSeverity.INFO}
                                        text={info.indication}
                                        cssClass="modal-create-config__mode__content__alert"
                                        fullWidth
                                    />
                                }
                            </FlexContent>
                            <FlexContent
                                direction={FlexContentDirection.COLUMN}
                                spacing={FlexContentSpacing.MEDIUM}
                                alignment={FlexContentAlignment.CENTER}
                                cssClass="modal-create-config__mode__footer"
                            >
                                <img
                                    src={textConfigurationMode("consent_mode_compliant.image")}
                                    title={textConfigurationMode("consent_mode_compliant.name")}
                                    alt={textConfigurationMode("consent_mode_compliant.name")}
                                />
                                <Button onClick={() => onClick(mode)} icon={{name: "east"}} reverse>
                                    {mode === ConfigurationModeName.CUSTOM ? textConfigurationMode("request_quote") : textConfigurationMode("setup")}
                                </Button>
                            </FlexContent>
                        </FlexContent>
                    )}
                </FlexContent>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalConfigMode;
