import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import {ModalContent, ModalNew, ModalTitle, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {Partner} from "../../utils/Partner";
import {Network} from "../../api/model/cmp/list/network-list/Network";

type ModalNetworkProps = {
    network: Network | undefined;
    partners: Partner[];
    onClose: () => void;
}

const ModalNetwork: FunctionComponent<ModalNetworkProps> = ({network, partners, onClose}) => {
    const {t: textConfigPartners} = useTranslation(TranslationPortalFile.CONFIG_PARTNERS);
    const [networkPartners, setNetworkPartners] = useState<Partner[]>([]);

    useEffect(() => {
        if (!network) return;

        const networkPartners = partners.filter((partner) => {
            return (partner.isIABVendor && network.hasIABVendor(partner.id)) ||
                (partner.isGoogleProvider && network.hasGoogleProvider(partner.id)) ||
                (partner.isSirdataVendor && network.hasSirdataVendor(partner.id));
        });
        setNetworkPartners(networkPartners);
    }, [network, partners]);

    return (
        <ModalNew onClose={onClose} active={!!network}>
            <ModalContent>
                <ModalTitle>{network?.name}</ModalTitle>
                <Table
                    columns={[
                        {width: 10, label: textConfigPartners("field.list"), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 15, label: textConfigPartners("field.id"), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 75, label: textConfigPartners("field.partner_name")}
                    ]}
                    pagination={MODAL_PAGE_SIZE}
                >
                    {networkPartners.map((partner) =>
                        <TableRow key={partner.id}>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.logo}</TableColumn>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.id}</TableColumn>
                            <TableColumn>{partner.name}</TableColumn>
                        </TableRow>
                    )}
                </Table>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalNetwork;
