import {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import SvgCookieIcon from "../../svg/SvgCookieIcon";
import SvgCookieShieldIcon from "../../svg/SvgCookieShieldIcon";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeToolbarPosition} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarPosition";
import {CmpConfigThemeToolbarSize} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarSize";
import {CmpConfigThemeToolbarStyle} from "../../../api/model/cmp/config/theme/CmpConfigThemeToolbarStyle";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigThemeBorderRadius} from "../../../api/model/cmp/config/theme/CmpConfigThemeBorderRadius";
import {CmpConfigThemeTextSize} from "../../../api/model/cmp/config/theme/CmpConfigThemeTextSize";
import {CmpConfigThemeFontFamily} from "../../../api/model/cmp/config/theme/CmpConfigThemeFontFamily";

type ToolbarProps = {
    theme: CmpConfigTheme;
    style?: CmpConfigThemeToolbarStyle;
    size?: CmpConfigThemeToolbarSize;
    isStatic?: boolean;
};

const Toolbar: FunctionComponent<ToolbarProps> = ({theme, style = CmpConfigThemeToolbarStyle.getByName(theme.toolbar.style), size = theme.toolbar.size, isStatic}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const PIXEL_SUFFIX = "px";

    const getBorderRadiusValue = () => {
        switch (theme.borderRadius) {
            case CmpConfigThemeBorderRadius.STRONG:
                return 24;
            case CmpConfigThemeBorderRadius.AVERAGE:
                return 12;
            case CmpConfigThemeBorderRadius.LIGHT:
                return 4;
            default:
                return 0;
        }
    };

    const getFontSizeValue = () => {
        switch (theme.textSize) {
            case CmpConfigThemeTextSize.BIG:
                return 15;
            case CmpConfigThemeTextSize.MEDIUM:
                return 14;
            case CmpConfigThemeTextSize.SMALL:
                return 13;
            default:
                return;
        }
    };

    const getButtonStyle = () => {
        return {
            display: "flex",
            "--main-color": theme.getColor(CmpConfigThemeModeField.MAIN_COLOR),
            "--background-color": theme.getColor(CmpConfigThemeModeField.BACKGROUND_COLOR),
            "--border-radius": `${getBorderRadiusValue()}${PIXEL_SUFFIX}`,
            "--font-family": theme.fontFamilyTitle,
            "--font-size": `${getFontSizeValue()}${PIXEL_SUFFIX}`
        };
    };

    const loadFontFamily = (fontFamily: CmpConfigThemeFontFamily) => {
        if (fontFamily === CmpConfigThemeFontFamily.HELVETICA) return;

        const fontFamilyName = fontFamily.replaceAll(" ", "-");
        const fileUrl = `https://cdn.abconsent.net/cmp/fonts/${fontFamilyName.toLowerCase()}/${fontFamilyName}.css`;

        let l = document.createElement("link");
        l.rel = "stylesheet";
        l.type = "text/css";
        l.media = "screen";
        l.href = fileUrl;
        document.getElementsByTagName("head")[0].appendChild(l);
    };

    useEffect(() => {
        loadFontFamily(theme.fontFamilyTitle);
    }, [theme]);

    return (
        <div className="toolbar">
            <div className="toolbar__content">
                {style === CmpConfigThemeToolbarStyle.ICON ?
                    <div className={clsx("toolbar__icon", {"toolbar__icon--static": isStatic})}>
                        <SvgCookieShieldIcon
                            small={size === CmpConfigThemeToolbarSize.SMALL}
                            color={theme.getColor(CmpConfigThemeModeField.MAIN_COLOR)}
                        />
                    </div> :
                    <button
                        className={clsx("toolbar__button",
                            {"toolbar__button--left": theme.toolbar.position === CmpConfigThemeToolbarPosition.LEFT},
                            {"toolbar__button--right": theme.toolbar.position === CmpConfigThemeToolbarPosition.RIGHT},
                            {"toolbar__button--small": size === CmpConfigThemeToolbarSize.SMALL},
                            {"toolbar__button--static": isStatic})
                        }
                        style={getButtonStyle()}
                    >
                        {style === CmpConfigThemeToolbarStyle.TEXT_ICON &&
                            <span className="toolbar__button__icon">
                                <SvgCookieIcon/>
                            </span>
                        }
                        <span>{textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TOOLBAR}.cookies_settings`)}</span>
                    </button>
                }
            </div>
        </div>
    );
};

export default Toolbar;
