import {useEffect, useState} from "react";
import {AlertSeverity, ButtonAdd, ButtonSize, Color, ContentBlock, LayoutRows, Loadable, MainContentPageHeader, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle, Tag, TagSize, TagStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import ModalConfigMode from "../../component/modal/ModalConfigMode";
import ModalDelegatedConfigAdvancedInfo, {ModalDelegatedConfigAdvancedInfoArgs, ModalDelegatedConfigAdvancedInfoUiEvent} from "../../component/modal/ModalDelegatedConfigAdvancedInfo";
import ModalDelegatedConfigDuplicate, {ModalDelegatedConfigDuplicateArgs, ModalDelegatedConfigDuplicateUiEvent} from "../../component/modal/ModalDelegatedConfigDuplicate";
import ModalDelegatedConfigFlashInfo from "../../component/modal/ModalDelegatedConfigFlashInfo";
import ModalDeleteConfig from "../../component/modal/ModalDeleteConfig";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigFilters} from "../../api/model/cmp/config/CmpConfigFilters";
import {CmpConfigSearchField, CmpConfigSearchQuery} from "../../api/model/cmp/config/CmpConfigSearchQuery";
import {CONFIGURATION_MODES_DELEGATED, ConfigurationModeName} from "../../utils/ConfigurationMode";
import {CMP_CONFIG_STATUSES, CmpConfigStatus} from "../../api/model/cmp/config/CmpConfigStatus";
import {DelegatedCmpRow} from "../../component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {PAGE_SIZE, pathDelegatedSitesConfigurations, TranslationPortalFile} from "../../utils/constants";
import {PortalQueryString} from "../../api/model/PortalQueryString";
import {session} from "../../api/ApiSession";
import {sortItems} from "../../common/utils/helper";
import {UIEventManager} from "../../common/utils/UIEventManager";

function DelegatedSitesConfigurations() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentCmpConfigs, setCurrentCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentDeleteCmp, setCurrentDeleteCmp] = useState<CmpConfig>();
    const [filters, setFilters] = useState<CmpConfigFilters>(new CmpConfigFilters());
    const [searchQuery, setSearchQuery] = useState<CmpConfigSearchQuery>(new CmpConfigSearchQuery().withDelegatedMode());

    const [isLoading, setLoading] = useState(true);
    const [isShowModalConfigFlashInfo, setShowModalConfigFlashInfo] = useState(false);
    const [isShowModalConfigMode, setShowModalConfigMode] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await session.loadAccount();

                switch (true) {
                    case location.state?.mode === ConfigurationModeName.ADVANCED:
                        UIEventManager.emit(ModalDelegatedConfigAdvancedInfoUiEvent, {
                            onClose: () => navigate(pathDelegatedSitesConfigurations)
                        } as ModalDelegatedConfigAdvancedInfoArgs);
                        navigate(pathDelegatedSitesConfigurations, {replace: true});
                        break;
                    case !!PortalQueryString.getParam(location.search, PortalQueryString.ID):
                        UIEventManager.emit(ModalDelegatedConfigAdvancedInfoUiEvent, {
                            cmpConfigId: PortalQueryString.getParam(location.search, PortalQueryString.ID),
                            onUpdate: (cmpConfig: CmpConfig) => {
                                const newCmpConfigs = [...cmpConfigs];
                                newCmpConfigs.splice(cmpConfigs.findIndex((item) => item.id === cmpConfig.id), 1, cmpConfig);
                                setCmpConfigs(newCmpConfigs);
                            },
                            onClose: () => navigate(pathDelegatedSitesConfigurations)
                        } as ModalDelegatedConfigAdvancedInfoArgs);
                        break;
                    default:
                        break;
                }
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [location, navigate, cmpConfigs]);

    useEffect(() => {
        (async () => {
            try {
                setFilters(await session.restCmpConfig.getFilters());
            } catch (e) {
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                let cmpConfigs = await session.restCmpConfig.list(searchQuery);
                cmpConfigs = sortItems(cmpConfigs, CmpConfigField.CREATION_TIME, true);
                setCmpConfigs(cmpConfigs);
                setCurrentCmpConfigs(cmpConfigs);
            } catch (e) {
            }
        })();
    }, [searchQuery]);

    useEffect(() => {
        const newCurrentCmpConfigs = cmpConfigs.filter((item) => !searchQuery.query || `${item.name} ${item.domain}`.toLowerCase().includes(searchQuery.query.toLowerCase()));
        setCurrentCmpConfigs(newCurrentCmpConfigs);
    }, [cmpConfigs, searchQuery]);

    const handleChangeSearchQuery = (field: CmpConfigSearchField, value: any) => {
        setSearchQuery((prevState) => new CmpConfigSearchQuery({...prevState, [field]: value}));
    };

    const handleChangeSortOrder = (field: string, reverseOrder?: boolean) => {
        setCmpConfigs((prevState) => sortItems(prevState, field, reverseOrder));
    };

    const handleAdd = () => {
        setShowModalConfigMode(true);
    };

    const handleDuplicate = async (cmpConfig: CmpConfig) => {
        UIEventManager.emit(ModalDelegatedConfigDuplicateUiEvent, {
            cmpConfigId: cmpConfig.id,
            onDuplicate: (cmpConfig: CmpConfig) => setCmpConfigs((cmpConfigs) => [cmpConfig, ...cmpConfigs]),
            onClose: () => navigate(pathDelegatedSitesConfigurations)
        } as ModalDelegatedConfigDuplicateArgs);
    };

    const handleToggleStatus = async (cmpConfig: CmpConfig) => {
        try {
            const cmpConfigToUpdate = await session.restCmpConfig.get(cmpConfig.id);
            cmpConfigToUpdate.status = cmpConfigToUpdate.status === CmpConfigStatus.ACTIVE ? CmpConfigStatus.INACTIVE : CmpConfigStatus.ACTIVE;
            const updatedCmpConfig = await session.restCmpConfig.update(cmpConfigToUpdate);
            const newCmpConfigs = [...cmpConfigs];
            newCmpConfigs.splice(cmpConfigs.findIndex((item) => item.id === cmpConfig.id), 1, updatedCmpConfig);
            setCmpConfigs(newCmpConfigs);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp_status"), AlertSeverity.DANGER);
        }
    };

    const handleDelete = async (cmpConfig: CmpConfig) => {
        if (!cmpConfig) return;
        try {
            await session.restCmpConfig.delete(cmpConfig.id);
            const newCmpConfigs = [...cmpConfigs];
            newCmpConfigs.splice(newCmpConfigs.findIndex((item) => item.id === cmpConfig.id), 1);
            setCmpConfigs(newCmpConfigs);
            setCurrentDeleteCmp(undefined);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.delete_cmp"), AlertSeverity.DANGER);
        }
    };

    const handleConfigModeChoice = (mode: ConfigurationModeName) => {
        switch (mode) {
            case ConfigurationModeName.ADVANCED:
                UIEventManager.emit(ModalDelegatedConfigAdvancedInfoUiEvent, {
                    onClose: () => navigate(pathDelegatedSitesConfigurations)
                } as ModalDelegatedConfigAdvancedInfoArgs);
                break;
            case ConfigurationModeName.FLASH:
                setShowModalConfigFlashInfo(true);
                break;
            default:
                break;
        }
        setShowModalConfigMode(false);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader
                    icon={{name: "settings", colorIcon: Color.CYAN}}
                    title={{label: textConfiguration("title"), tag: <Tag label={textConfiguration("delegation")} size={TagSize.XSMALL} style={TagStyle.DEFAULT_CYAN_LIGHT}/>}}
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock>
                            <SearchToolbar
                                searchBar={{
                                    placeholder: textConfiguration("search.site"),
                                    value: searchQuery.query,
                                    onChange: (value) => handleChangeSearchQuery(CmpConfigSearchField.QUERY, value)
                                }}
                                actions={[<ButtonAdd key={0} size={ButtonSize.MEDIUM} onClick={handleAdd}/>]}
                                canHideFilters
                            >
                                <SearchField label={textConfiguration(`field.${CmpConfigField.STATUS}`)}>
                                    <Select
                                        value={searchQuery.status}
                                        options={[
                                            {value: "", label: textConfiguration("option.ALL")},
                                            ...CMP_CONFIG_STATUSES.map((it) => ({value: it, label: textConfiguration(`option.${it}`)}))
                                        ]}
                                        onChange={(option) => option && handleChangeSearchQuery(CmpConfigSearchField.STATUS, option.value)}
                                    />
                                </SearchField>
                                <SearchField label={textConfiguration(`field.${CmpConfigField.DELEGATED_PARTNER_COMPANY}`)}>
                                    <Select
                                        value={searchQuery.partner_id?.toString() || ""}
                                        options={[
                                            {value: "", label: textConfiguration("option.ALL")},
                                            ...filters.delegated_partners.map((it) => ({
                                                value: it.value.toString() || "",
                                                label: it.label || "",
                                                partnerId: it.value
                                            }))
                                        ]}
                                        onChange={(option) => option && handleChangeSearchQuery(CmpConfigSearchField.PARTNER_ID, option.partnerId)}
                                    />
                                </SearchField>
                            </SearchToolbar>
                            {!!currentCmpConfigs.length ?
                                <Table
                                    columns={[
                                        {width: 10, label: textConfiguration(`field.${CmpConfigField.STATUS}`)},
                                        {width: 10, label: textConfiguration(`field.${CmpConfigField.ID}`)},
                                        {width: 30, label: textConfiguration(`field.${CmpConfigField.NAME}`)},
                                        {width: 30, label: textConfiguration(`field.${CmpConfigField.DOMAIN}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 10, label: textConfiguration(`field.${CmpConfigField.DELEGATED_PARTNER_COMPANY}`), sort: {field: CmpConfigField.DELEGATED_PARTNER_COMPANY}, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 5, label: textConfiguration(`field.${CmpConfigField.CREATION_TIME}`), sort: {field: CmpConfigField.CREATION_TIME}},
                                        {width: 5, styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={PAGE_SIZE}
                                    onSort={handleChangeSortOrder}
                                >
                                    {currentCmpConfigs.map((item: CmpConfig) =>
                                        <DelegatedCmpRow
                                            key={item.id}
                                            cmp={item}
                                            onPublish={() => handleToggleStatus(item)}
                                            onCopy={() => handleDuplicate(item)}
                                            onDelete={() => setCurrentDeleteCmp(item)}
                                        />)
                                    }
                                </Table> :
                                <SearchError query={searchQuery.query}/>
                            }
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
                <ModalConfigMode
                    active={isShowModalConfigMode}
                    modes={CONFIGURATION_MODES_DELEGATED}
                    onClose={() => setShowModalConfigMode(false)}
                    onClick={handleConfigModeChoice}
                />
                <ModalDelegatedConfigAdvancedInfo/>
                <ModalDelegatedConfigFlashInfo
                    active={isShowModalConfigFlashInfo}
                    onClose={() => setShowModalConfigFlashInfo(false)}
                />
                <ModalDelegatedConfigDuplicate/>
                <ModalDeleteConfig
                    cmpConfig={currentDeleteCmp}
                    onClose={() => setCurrentDeleteCmp(undefined)}
                    onDelete={() => handleDelete(currentDeleteCmp!)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default DelegatedSitesConfigurations;
