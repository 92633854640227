import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpConfigFilter<T extends string | number> extends ApiModel {
    value: T = 0 as T;
    label?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
