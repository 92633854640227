import React, {useEffect, useState} from "react";
import {Action, AlertSeverity, Box, BoxProps, BoxRadius, Button, ButtonStyle, Color, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, Formatter, Loadable, MainContentPageHeader, SearchField, SearchToolbar, SelectAutocomplete} from "@sirdata/ui-lib";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {buildAndDownloadCSV} from "../../../common/utils/portal";
import {ChartContainer, ChartLine} from "../../../component/widget";
import {CHART_DATASET_DEFAULT_STYLES, GraphData} from "../../../utils/stat/chart_options";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {handleCatchError} from "../../../common/utils/helper";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {PathHelper} from "../../../common/utils/PathHelper";
import {pathSitesAnalyticsHits, pathSitesAnalyticsHitsWithId, TranslationPortalFile} from "../../../utils/constants";
import {SearchFieldPeriod} from "../../../component/snippet";
import {session} from "../../../api/ApiSession";
import {slugify} from "../../../common/utils/string";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {UIEventManager} from "../../../common/utils/UIEventManager";

function SitesAnalyticsHits() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const navigate = useNavigate();
    const {id: configId} = useParams<{ id: string }>();

    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_HIT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_HIT_PERIOD.interval);
    const [hitGraphData, setHitGraphData] = useState<GraphData>();
    const [totalHits, setTotalHits] = useState(0);

    const [isLoading, setLoading] = useState(true);

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_HIT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                let start = period.interval.start;
                let end = period.interval.end;
                if (period === StatPeriod.CUSTOM) {
                    start = interval.start;
                    end = interval.end;
                }

                const hitStats = await session.restPartner.getDailyHits(start, end, configId);
                setHitGraphData({
                    labels: hitStats.map((it) => it.date),
                    datasets: [{
                        label: textAnalytics("label.hits"),
                        data: hitStats.map((it) => it.hits),
                        ...CHART_DATASET_DEFAULT_STYLES
                    }]
                });
                setTotalHits(hitStats.reduce((total, {hits}) => total + hits, 0));
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
            } finally {
                setLoading(false);
            }
        })();
    }, [textAnalytics, period, interval, configId]);

    const downloadCSVFile = () => {
        const fileTitle = textAnalytics("file.title", {name: slugify(cmpConfigs.find((config) => config.id === configId)?.name || "all")});
        const headers = [textAnalytics("file.column.date"), textAnalytics("file.column.hits")];
        let rows: string[][] = [];

        if (hitGraphData) {
            for (let i = 0; i < hitGraphData.labels.length; i++) {
                rows.push([hitGraphData.labels[i], hitGraphData.datasets[0].data[i].toString()]);
            }
        }
        buildAndDownloadCSV(fileTitle, headers, rows);
    };

    useEffect(() => {
        (async () => {
            try {
                setCmpConfigs(await session.restCmpConfig.list());
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load data", e.message));
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textAnalytics("hits.title")} icon={{name: "bar_chart", colorIcon: Color.CYAN}}/>
                <SearchToolbar>
                    <SearchField label={textAnalytics("label.site")}>
                        <SelectAutocomplete
                            value={cmpConfigs.find((config) => config.id === configId)?.id || ""}
                            options={[
                                {value: "", label: `${textAnalytics("label.all")}`},
                                ...cmpConfigs.map(({id, name, domain}) => ({value: id, label: `${name} (${domain})`}))
                            ]}
                            onChange={(option) => navigate(!!option?.value ? PathHelper.buildPathWithId(pathSitesAnalyticsHitsWithId, option.value as string) : pathSitesAnalyticsHits)}
                        />
                    </SearchField>
                    <SearchFieldPeriod
                        period={period}
                        onChangePeriod={handleChangePeriod}
                        customInterval={interval}
                        onChangeCustomInterval={handleChangeInterval}
                    />
                </SearchToolbar>
                <Loadable loading={isLoading}>
                    <ContentBlock header={{title: {label: textAnalytics("hits.evolution_by_hits")}}} cssClass="analytics__hits">
                        <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD}>
                            <div className="analytics__hits__metric">
                                <span className="analytics__hits__metric__header">{textAnalytics("metric.hits_total")}</span>
                                <span className="analytics__hits__metric__value">{Formatter.formatNumber(totalHits)}</span>
                            </div>
                            <ChartContainer height="35rem">
                                <ChartLine data={hitGraphData}/>
                            </ChartContainer>
                        </Box>
                        <ContentBlockRow>
                            <ContentBlockRowElementRight>
                                <Button
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    icon={Action.EXPORT.icon}
                                    onClick={downloadCSVFile}
                                    children={textAnalytics("action.csv_export")}
                                />
                            </ContentBlockRowElementRight>
                        </ContentBlockRow>
                    </ContentBlock>
                </Loadable>
            </MainContent>
        </Wrapper>
    );
}

export default SitesAnalyticsHits;
