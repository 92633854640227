import React from "react";
import {FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, Icon} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {TranslationPortalFile} from "../../../utils/constants";

type ConfigDelegatedAlertProps = {
    cmpConfig: CmpConfig;
}

const ConfigDelegatedAlert: React.FC<ConfigDelegatedAlertProps> = ({cmpConfig}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    if (!cmpConfig.delegated_partner_company) return null;

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            justify={FlexContentJustify.CENTER}
            alignment={FlexContentAlignment.CENTER}
            spacing={FlexContentSpacing.SMALL}
            cssClass="config-delegated-alert"
        >
            <Icon name="warning" outlined/>
            <span dangerouslySetInnerHTML={{__html: textConfiguration("message.delegated_configuration", {company: cmpConfig.delegated_partner_company})}}/>
        </FlexContent>
    );
};

export default ConfigDelegatedAlert;
