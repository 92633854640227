import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpConfigFilter} from "./CmpConfigFilter";
import {CmpConfigFiltersField} from "./CmpConfigFiltersField";

export class CmpConfigFilters extends ApiModel {
    delegated_partners: CmpConfigFilter<number>[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(CmpConfigFiltersField.DELEGATED_PARTNERS, CmpConfigFilter);
    }
}
