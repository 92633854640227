import {AlertSeverity, ModalContent, ModalNew, ModalNewSize} from "@sirdata/ui-lib";
import {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigPropertiesCreationType} from "../../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {ConfigFlashInfo} from "../snippet";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ModalDelegatedConfigFlashInfoProps = {
    active: boolean;
    onClose: () => void;
}

const ModalDelegatedConfigFlashInfo: FunctionComponent<ModalDelegatedConfigFlashInfoProps> = ({active, onClose}) => {
    const {t: textConfigurationFlash} = useTranslation(TranslationPortalFile.CONFIGURATION_FLASH);
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = async (cmpConfig: CmpConfig, delegatedPartnerId: number) => {
        try {
            setSubmitting(true);
            cmpConfig.properties.creation_type = CmpConfigPropertiesCreationType.FLASH;
            const createdCmpConfig = await session.restCmpConfig.create(cmpConfig, delegatedPartnerId);
            navigate(createdCmpConfig.getPath(CmpConfigStepName.INTEGRATION));
            setSubmitting(false);
        } catch (e) {
            UIEventManager.alert(textConfigurationFlash("error.create_cmp"), AlertSeverity.DANGER);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active} size={ModalNewSize.MAX_WIDTH}>
            <ModalContent>
                <ConfigFlashInfo
                    onSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    onCancel={onClose}
                    forDelegated
                />
            </ModalContent>
        </ModalNew>
    );
};

export default ModalDelegatedConfigFlashInfo;
