import React, {useState} from "react";
import {AdBlockDetectedWrapper} from "adblock-detect-react";
import {Icon, ModalContent, ModalNew, ModalTitle, ModalTitleSize, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

const ModalAdBlockDetector: React.FC = () => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const [active, setActive] = useState(true);

    return (
        <AdBlockDetectedWrapper>
            <ModalNew onClose={() => setActive(false)} active={active} cssClass="modal-adblock-detector">
                <ModalContent centered>
                    <Icon name="warning" outlined cssClass="modal-adblock-detector__icon"/>
                    <ModalTitle size={ModalTitleSize.HUGE}>
                        {t("modal.ad_blocker.title")}
                    </ModalTitle>
                    <Paragraph alignment={ParagraphAlignment.CENTER}>
                        {t("modal.ad_blocker.content")}
                    </Paragraph>
                </ModalContent>
            </ModalNew>
        </AdBlockDetectedWrapper>
    );
};

export default ModalAdBlockDetector;
