import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    AlertSeverity,
    CircleStep,
    FieldBlock,
    FlexContent,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    Select,
    Tag,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";

import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigThemeField} from "../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CmpConfigThemeModeField} from "../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigThemePosition} from "../../api/model/cmp/config/theme/CmpConfigThemePosition";
import {CMP_CONFIG_THEME_BORDER_RADIUS} from "../../api/model/cmp/config/theme/CmpConfigThemeBorderRadius";
import {CMP_CONFIG_THEME_FONT_FAMILIES} from "../../api/model/cmp/config/theme/CmpConfigThemeFontFamily";
import {CMP_CONFIG_THEME_TEXT_SIZES} from "../../api/model/cmp/config/theme/CmpConfigThemeTextSize";
import {ConfigDelegatedAlert, ConfigHeader, ConfigThemeColors, ConfigThemePosition, Preview} from "../../component/snippet";
import {handleCatchError} from "../../common/utils/helper";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

function ConfigBanner() {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const {t: textConfigBanner} = useTranslation(TranslationPortalFile.CONFIG_BANNER);
    const {id: configId} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isEditMode, setEditMode] = useState(true);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isDelegatedCmpConfig, setDelegatedCmpConfig] = useState(false);

    const handleChangeTheme = (name: string, value: string, mode?: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);

        if (mode) {
            newCmpConfig.settings.theme[mode][name] = value.trim();
        } else {
            newCmpConfig.settings.theme[name] = value.trim();
        }

        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleChangeOverlay = (checked: boolean) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.theme.overlay = checked;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleSave = async () => {
        try {
            if (isEditMode && !hasUnsavedChanges) {
                return;
            }

            if (cmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.BANNER)?.name) {
                cmpConfig.step = CmpConfigStepName.BANNER;
            }

            const updatedCmpConfig = await session.restCmpConfig.update(cmpConfig);
            setCmpConfig(updatedCmpConfig);

            if (isEditMode) {
                setUnsavedChanges(false);
            }
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp"), AlertSeverity.DANGER);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const newCmpConfig = await session.restCmpConfig.get(configId);
                newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);
                setDelegatedCmpConfig(!!newCmpConfig.delegated_partner_company);
            } catch (e) {
                handleCatchError(e, (e) => {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    }
                    console.error("Failed to load data", e.message);
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [configId]);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges}/>
            <ConfigHeader
                cmpConfig={cmpConfig}
                step={CmpConfigStepName.BANNER}
                editMode={isEditMode}
                delegatedMode={isDelegatedCmpConfig}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={handleSave}
            />
            <ConfigDelegatedAlert cmpConfig={cmpConfig}/>
            <MainContent>
                <MainContentPageHeader
                    title={textConfigBanner(isEditMode ? "title_edit" : "title")}
                    icon={!isEditMode ? <CircleStep step={CmpConfigStepper.getStepPosition(CmpConfigStepName.BANNER)}/> : undefined}
                    cssClass="configuration-main-title"
                />
                <LayoutRows>
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.SMALL} layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <Loadable loading={isLoading}>
                            <div>
                                <Accordion>
                                    <AccordionItem heading={<Tag label={textConfigBanner("section.layout")} icon={{name: "devices", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.POSITION}.label`)}>
                                                    <ConfigThemePosition
                                                        value={cmpConfig.settings.theme.position || CmpConfigThemePosition.CENTER}
                                                        onChange={(option) => handleChangeTheme(CmpConfigThemeField.POSITION, option)}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TEXT_SIZE}.label`)}>
                                                        <Select
                                                            value={cmpConfig.settings.theme.textSize}
                                                            options={CMP_CONFIG_THEME_TEXT_SIZES.map((it) => ({value: it, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TEXT_SIZE}.${it}`)}))}
                                                            onChange={(option) => option && handleChangeTheme(CmpConfigThemeField.TEXT_SIZE, option.value.toString())}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.BORDER_RADIUS}.label`)}>
                                                        <Select
                                                            value={cmpConfig.settings.theme.borderRadius}
                                                            options={CMP_CONFIG_THEME_BORDER_RADIUS.map((it) => ({value: it, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.BORDER_RADIUS}.${it}`)}))}
                                                            onChange={(option) => option && handleChangeTheme(CmpConfigThemeField.BORDER_RADIUS, option.value.toString())}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem heading={<Tag label={textConfigBanner("section.colors")} icon={{name: "palette", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                                        <AccordionItemContent>
                                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.MAIN_COLOR}`)}>
                                                    <ConfigThemeColors
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.MAIN_COLOR}
                                                        onChange={handleChangeTheme}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.BACKGROUND_COLOR}`)}>
                                                    <ConfigThemeColors
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.BACKGROUND_COLOR}
                                                        onChange={handleChangeTheme}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.BORDER_COLOR}`)}>
                                                    <ConfigThemeColors
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.BORDER_COLOR}
                                                        onChange={handleChangeTheme}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.TITLE_COLOR}`)}>
                                                    <ConfigThemeColors
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.TITLE_COLOR}
                                                        onChange={handleChangeTheme}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.TEXT_COLOR}`)}>
                                                    <ConfigThemeColors
                                                        theme={cmpConfig.settings.theme}
                                                        field={CmpConfigThemeModeField.TEXT_COLOR}
                                                        onChange={handleChangeTheme}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem heading={<Tag label={textConfigBanner("section.font")} icon={{name: "font_download", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                                        <AccordionItemContent>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.FONT_FAMILY_TITLE}`)}>
                                                    <Select
                                                        value={cmpConfig.settings.theme.fontFamilyTitle}
                                                        options={CMP_CONFIG_THEME_FONT_FAMILIES.map((it) => ({value: it, label: it}))}
                                                        onChange={(option) => option && handleChangeTheme(CmpConfigThemeField.FONT_FAMILY_TITLE, option.value.toString())}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.FONT_FAMILY}`)}>
                                                    <Select
                                                        value={cmpConfig.settings.theme.fontFamily}
                                                        options={CMP_CONFIG_THEME_FONT_FAMILIES.map((it) => ({value: it, label: it}))}
                                                        onChange={(option) => option && handleChangeTheme(CmpConfigThemeField.FONT_FAMILY, option.value.toString())}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem heading={<Tag label={textConfigBanner("section.overlay")} icon={{name: "blur_on"}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FieldBlock>
                                                    <ToggleSwitch
                                                        name={`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.OVERLAY}`}
                                                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.OVERLAY}`)}
                                                        checked={!!cmpConfig.settings.theme.overlay}
                                                        onChange={handleChangeOverlay}
                                                    />
                                                </FieldBlock>
                                                {!!cmpConfig.settings.theme.overlay &&
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.OVERLAY_COLOR}`)}>
                                                        <ConfigThemeColors
                                                            theme={cmpConfig.settings.theme}
                                                            field={CmpConfigThemeModeField.OVERLAY_COLOR}
                                                            onChange={handleChangeTheme}
                                                        />
                                                    </FieldBlock>
                                                }
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <div>
                                <div className="configuration-sticky-block">
                                    <Preview cmpConfig={cmpConfig} withActions/>
                                </div>
                            </div>
                        </Loadable>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ConfigBanner;
