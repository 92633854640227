import {ApiModel} from "../../../../common/api/model/ApiModel";

export enum CmpConfigSearchField {
    QUERY = "query",
    STATUS = "status",
    PARTNER_ID = "partner_id"
}

export class CmpConfigSearchQuery extends ApiModel {
    private delegated_mode: boolean = false;
    query: string = "";
    status: string = "";
    partner_id?: number;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    withDelegatedMode(): CmpConfigSearchQuery {
        this.delegated_mode = true;
        return this;
    }

    getJsonParameters() {
        return {
            delegated_mode: this.delegated_mode,
            query: this.query,
            status: this.status,
            partner_id: this.partner_id
        };
    }
}
