import React, {useEffect, useState} from "react";
import {Action, Box, BoxSpacing, Button, ButtonLink, ButtonLinkStyle, ButtonStyle, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, FormLayoutButtons, FormLayoutRows, SvgSirdataLogoWhite, TranslationLibFile, Wrapper} from "@sirdata/ui-lib";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {PortalQueryString} from "../../../api/model/PortalQueryString";
import {Preview} from "../../../component/snippet";
import {pathConfigurationFlashInformation, TranslationPortalFile} from "../../../utils/constants";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../../common/api/CommonApiClient";
import {CmpPreviewHelper} from "../../../utils/CmpPreviewHelper";
import {CmpConfigStepName} from "../../../utils/CmpConfigStepper";
import SvgTada from "../../../component/svg/SvgTada";
import {handleCatchError} from "../../../common/utils/helper";

function ConfigurationFlashPreview() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfigurationFlash} = useTranslation(TranslationPortalFile.CONFIGURATION_FLASH);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());

    useEffect(() => {
        const newCmpId = PortalQueryString.getParam(location.search, PortalQueryString.ID);
        if (!newCmpId?.length) return;

        (async () => {
            try {
                const newCmpConfig = await session.restCmpConfig.get(newCmpId);
                setCmpConfig(newCmpConfig);
            } catch (e) {
                handleCatchError(e, (e) => {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    }
                    console.error("Failed to load data", e.message);
                });
            }
        })();
    }, [location]);

    return (
        <Wrapper cssClass="onboarding">
            <div className="onboarding-page">
                <div className="onboarding-header">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <div className="title">{textConfigurationFlash("title")}</div>
                </div>
                <div className="onboarding-form">
                    <Box cssClass="configuration-flash" spacing={BoxSpacing.XLARGE}>
                        <FormLayoutRows>
                            <span className="configuration-flash__title">{textConfigurationFlash("confirmation_title")}</span>
                            {cmpConfig.isOutdated() ?
                                <div className="configuration-flash__preview">
                                    <Preview cmpConfig={cmpConfig} withActions/>
                                    <ButtonLink
                                        icon={Action.OPEN.icon}
                                        style={ButtonLinkStyle.GREY}
                                        onClick={() => CmpPreviewHelper.openPreview(cmpConfig)}
                                        cssClass="preview__link"
                                        reverseUnderline
                                    >
                                        {textConfigurationFlash("preview")}
                                    </ButtonLink>
                                </div> :
                                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER}>
                                    <SvgTada/>
                                </FlexContent>
                            }
                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                <span className="configuration-flash__advanced-config" dangerouslySetInnerHTML={{__html: textConfigurationFlash("install_or_change_config", {advancedConfigUrl: cmpConfig.getPath(CmpConfigStepName.BANNER)})}}/>
                                <FormLayoutButtons>
                                    <Button
                                        style={ButtonStyle.DEFAULT_MIDNIGHT}
                                        icon={{name: "west"}}
                                        onClick={() => navigate(`${pathConfigurationFlashInformation}?${PortalQueryString.ID}=${cmpConfig.id}`)}
                                    >
                                        {textCommon(Action.EDIT.labelKey)}
                                    </Button>
                                    <Button onClick={() => navigate(cmpConfig.getPath(CmpConfigStepName.INTEGRATION))}>
                                        {textConfigurationFlash("deploy_cmp")}
                                    </Button>
                                </FormLayoutButtons>
                            </FlexContent>
                        </FormLayoutRows>
                    </Box>
                </div>
            </div>
        </Wrapper>
    );
}

export default ConfigurationFlashPreview;
