import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    FormLayoutTitle,
    InputDomain,
    InputText,
    ModalContent,
    ModalNew,
    Select
} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigFilter} from "../../api/model/cmp/config/CmpConfigFilter";
import {CmpConfigPropertiesCreationType} from "../../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {FormLayoutMessage} from "../../common/component/snippet";
import {handleCatchError} from "../../common/utils/helper";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {validateUrl} from "../../common/utils/string";

export type ModalDelegatedConfigAdvancedInfoArgs = {
    cmpConfigId?: string;
    onClose?: () => void;
    onUpdate?: (cmpConfig: CmpConfig) => void;
}

type ConfigInfoFormErrors = {
    domain?: boolean;
    privacyPolicy?: boolean;
}

const ModalDelegatedConfigAdvancedInfo: FunctionComponent = () => {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [formErrors, setFormErrors] = useState<ConfigInfoFormErrors>();
    const [delegatedPartners, setDelegatedPartners] = useState<CmpConfigFilter<number>[]>([]);
    const [currentDelegatedPartnerId, setCurrentDelegatedPartnerId] = useState<number>(0);

    const [isActive, setActive] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [onClose, setOnClose] = useState<() => void>();
    const [onUpdate, setOnUpdate] = useState<(cmpConfig: CmpConfig) => void>();

    useEffect(() => {
        UIEventManager.addListener(ModalDelegatedConfigAdvancedInfoUiEvent, (args: ModalDelegatedConfigAdvancedInfoArgs) => {
            const {cmpConfigId, onClose, onUpdate} = args;
            if (cmpConfigId) {
                (async () => {
                    try {
                        const cmpConfig = await session.restCmpConfig.get(cmpConfigId);
                        setCmpConfig(cmpConfig);
                        setCurrentDelegatedPartnerId(cmpConfig.id_partner);
                    } catch (e) {
                        setCmpConfig(new CmpConfig());
                    }
                })();
            } else {
                setCmpConfig(new CmpConfig());
            }
            setOnClose(() => onClose);
            setOnUpdate(() => onUpdate);
            setActive(true);
        });

        return () => {
            UIEventManager.removeAllListeners(ModalDelegatedConfigAdvancedInfoUiEvent);
        };
    }, [cmpConfig]);

    useEffect(() => {
        (async () => {
            try {
                const filters = await session.restCmpConfig.getFilters();
                setDelegatedPartners(filters.delegated_partners);
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load delegated partners", e.message));
            }
        })();
    }, []);

    const handleChange = (field: CmpConfigField, value: any) => {
        setCmpConfig((prevState) => new CmpConfig({...prevState, [field]: value}));
    };

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        handleChange(CmpConfigField.SETTINGS, new CmpConfigSettings({...cmpConfig.settings, [field]: value}));
    };

    const handleSubmit = async (e?: FormEvent) => {
        if (!cmpConfig) return;
        if (e) e.preventDefault();

        const newCmpConfig = new CmpConfig(cmpConfig);
        let formErrors: ConfigInfoFormErrors = {domain: false, privacyPolicy: false};

        newCmpConfig.domain = new URL(newCmpConfig.domain.includes("http") ? newCmpConfig.domain : `https://${newCmpConfig.domain}`).hostname;
        formErrors.domain = !validateUrl(newCmpConfig.domain);

        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        if (!newCmpConfig.settings.privacyPolicy.includes("http")) {
            newCmpConfig.settings.privacyPolicy = `https://${newCmpConfig.settings.privacyPolicy}`;
        }
        formErrors.privacyPolicy = !validateUrl(newCmpConfig.settings.privacyPolicy);

        if (formErrors.domain || formErrors.privacyPolicy) {
            setFormErrors(formErrors);
            return;
        }

        setLoading(true);
        try {
            if (newCmpConfig.id) {
                const updatedCmpConfig = await session.restCmpConfig.update(newCmpConfig);
                onUpdate && onUpdate(updatedCmpConfig);
                setLoading(false);
                handleClose();
            } else {
                newCmpConfig.properties.creation_type = CmpConfigPropertiesCreationType.ADVANCED;
                const createdCmpConfig = await session.restCmpConfig.create(newCmpConfig, currentDelegatedPartnerId);
                navigate(createdCmpConfig.getPath(CmpConfigStepName.BANNER));
            }
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp"), AlertSeverity.DANGER);
            setLoading(false);
        }
    };

    const handleClose = () => {
        setFormErrors({});
        setCmpConfig(new CmpConfig());
        setActive(false);
        onClose && onClose();
    };

    const hasEmptyField = () => {
        return !cmpConfig.name || !cmpConfig.domain || !cmpConfig.settings.privacyPolicy || !currentDelegatedPartnerId;
    };

    return (
        <ModalNew onClose={handleClose} active={isActive}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>{textConfiguration("modal_config.site_info")}</FormLayoutTitle>
                    <FormLayoutColumns>
                        <FieldBlock
                            label={textConfiguration(`field.${CmpConfigField.NAME}`)}
                            required
                        >
                            <InputText
                                placeholder={textConfiguration(`placeholder.${CmpConfigField.NAME}`)}
                                value={cmpConfig.name}
                                onChange={(value) => handleChange(CmpConfigField.NAME, value)}
                                autoFocus
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textConfiguration(`field.${CmpConfigField.DOMAIN}`)}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputDomain
                                placeholder={textConfiguration(`placeholder.${CmpConfigField.DOMAIN}`)}
                                value={cmpConfig.domain}
                                onChange={(value) => handleChange(CmpConfigField.DOMAIN, value.toLowerCase())}
                            />
                            {!!formErrors?.domain &&
                                <FormLayoutMessage message={t("error.invalid_domain")} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputText
                            value={cmpConfig.settings.privacyPolicy}
                            placeholder={textConfiguration(`placeholder.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                            onChange={(value) => handleChangeSettings(CmpConfigSettingsField.PRIVACY_POLICY, value.toLowerCase())}
                        />
                        {!!formErrors?.privacyPolicy &&
                            <FormLayoutMessage message={t("error.invalid_url")} severity={AlertSeverity.DANGER} small/>
                        }
                    </FieldBlock>
                    <FieldBlock
                        label={textConfiguration(`field.${CmpConfigField.ID_PARTNER}`)}
                        content={{direction: FlexContentDirection.COLUMN}}
                        tooltip={(!!cmpConfig.id && delegatedPartners.length > 1) ? textConfiguration("message.update_cmp_partner_not_allowed") : undefined}
                        required={!cmpConfig.id}
                    >
                        <Select
                            value={currentDelegatedPartnerId.toString()}
                            options={delegatedPartners.map((it) => ({
                                value: it.value.toString() || "",
                                label: it.label || "",
                                partnerId: it.value
                            }))}
                            onChange={(option) => option && setCurrentDelegatedPartnerId(option.partnerId)}
                            disabled={!!cmpConfig.id}
                        />
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={handleClose}/>
                        <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()} loading={isLoading}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalDelegatedConfigAdvancedInfo;
export const ModalDelegatedConfigAdvancedInfoUiEvent = "ModalDelegatedConfigAdvancedInfo";
