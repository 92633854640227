import moment from "moment";
import {Action, Button, ButtonSize, ButtonStyle, Color, FormLayoutButtons, FormLayoutRows, Icon, IconTooltip, MainHeaderSubLayout, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalNew, SplitButton, TranslationLibFile, TranslationLibKey} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigStepName} from "../../../utils/CmpConfigStepper";
import {ConfigHeaderMenu} from "../index";
import {Formatter} from "../../../common/utils/Formatter";
import {pathDelegatedSitesConfigurations, pathSitesConfigurations, TranslationPortalFile} from "../../../utils/constants";

type ConfigHeaderEditProps = {
    step: CmpConfigStepName;
    hasUnsavedChanges: boolean;
    onSave: () => Promise<void>;
    onOpenPreview?: () => void;
    delegatedMode: boolean;
    cmpConfig?: CmpConfig;
}

const ConfigHeaderEdit: FunctionComponent<ConfigHeaderEditProps> = ({step, delegatedMode, hasUnsavedChanges, onSave, onOpenPreview = () => {}, cmpConfig}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const navigate = useNavigate();

    const [isShowEditWarning, setShowEditWarning] = useState(false);
    const [shouldConfirmBeforeQuit, setShouldConfirmBeforeQuit] = useState(hasUnsavedChanges);
    const [goToPath, setGoToPath] = useState("");
    const [isChangesSaved, setChangesSaved] = useState(false);

    useEffect(() => {
        setShouldConfirmBeforeQuit(hasUnsavedChanges);
    }, [hasUnsavedChanges]);

    const handleSave = async () => {
        await onSave();
        setChangesSaved(true);
        setTimeout(() => setChangesSaved(false), 1000);
    };

    const handleGoTo = (path: string) => {
        if (shouldConfirmBeforeQuit) {
            setGoToPath(path);
            setShowEditWarning(true);
        } else {
            navigate(path);
        }
    };

    const handleGoBackToSites = () => {
        handleGoTo(delegatedMode ? pathDelegatedSitesConfigurations : pathSitesConfigurations);
    };

    const buildInfo = () => {
        if (!cmpConfig || !(!!cmpConfig.last_update || !!cmpConfig.creation_time)) {
            return <></>;
        }

        const infos = [];
        if (!!cmpConfig.creation_time) {
            infos.push(textConfiguration("tooltip.created_at", {date: Formatter.formatDate(moment.utc(cmpConfig.creation_time), Formatter.DATETIME_FORMAT)}));
        }
        if (!!cmpConfig.last_update && cmpConfig.last_update !== cmpConfig.creation_time) {
            infos.push(textConfiguration("tooltip.last_updated_at", {date: Formatter.formatDate(moment.utc(cmpConfig.last_update), Formatter.DATETIME_FORMAT)}));
        }

        return (
            <IconTooltip
                icon={{name: "info", outlined: true, colorIcon: Color.MIDNIGHT_LIGHTER}}
                text={infos.join("<br/>")}
                cssClass="config-header__buttons__info"
            />
        );
    };

    return (
        <MainHeaderSubLayout cssClass="config-header">
            <ConfigHeaderMenu step={step} onChangePath={handleGoTo}/>
            <div className="config-header__buttons">
                {isChangesSaved ? (
                    <span className="config-header__buttons__label">
                        <Icon name="check"/>{textConfiguration("message.changes_saved")}
                    </span> ) : (
                    buildInfo()
                )}
                {step === CmpConfigStepName.INTEGRATION ?
                    <>
                        <SplitButton
                            size={ButtonSize.SMALL}
                            style={ButtonStyle.PRIMARY_WHITE}
                            actions={[
                                {label: textConfiguration("action.back_to_sites"), onClick: handleGoBackToSites},
                                {label: textConfiguration("action.preview"), onClick: onOpenPreview}
                            ]}
                        />
                        <div className="inline-buttons">
                            <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_WHITE} icon={{name: "visibility"}} onClick={onOpenPreview}>
                                {textConfiguration("action.preview")}
                            </Button>
                            <Button
                                size={ButtonSize.SMALL}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                icon={Action.BACK.icon}
                                onClick={handleGoBackToSites}
                            >
                                {textConfiguration("action.back_to_sites")}
                            </Button>
                        </div>
                    </> :
                    <>
                        <SplitButton
                            size={ButtonSize.SMALL}
                            style={ButtonStyle.PRIMARY_GREEN}
                            actions={[
                                {label: textCommon(Action.SAVE.labelKey), onClick: handleSave},
                                {label: textConfiguration("action.back_to_sites"), onClick: handleGoBackToSites}
                            ]}
                        />
                        <div className="inline-buttons">
                            <Button
                                size={ButtonSize.SMALL}
                                icon={Action.BACK.icon}
                                style={ButtonStyle.PRIMARY_WHITE}
                                onClick={handleGoBackToSites}
                            >
                                {textConfiguration("action.back_to_sites")}
                            </Button>
                            <Button
                                size={ButtonSize.SMALL}
                                icon={Action.SAVE.icon}
                                style={ButtonStyle.PRIMARY_GREEN}
                                onClick={handleSave}
                            >
                                {textCommon(Action.SAVE.labelKey)}
                            </Button>
                        </div>
                    </>
                }
            </div>
            <ModalNew active={isShowEditWarning} onClose={() => setShowEditWarning(false)}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textConfiguration("message.warning_edit")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setShowEditWarning(false)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={() => navigate(goToPath)}
                            >
                                {textCommon(TranslationLibKey.ACTION_QUIT)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </MainHeaderSubLayout>
    );
};

export default ConfigHeaderEdit;
