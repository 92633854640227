import React, {useEffect, useState} from "react";
import ReactFlow, {Controls} from "react-flow-renderer";
import {Accordion, AccordionItem, AccordionItemContent, LayoutRows, Loadable, MainContentPageHeader, Tabs} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import initialElementsEN from "./flow-chart-config/GoogleComplianceFlowChartConfigEn";
import initialElementsFR from "./flow-chart-config/GoogleComplianceFlowChartConfigFr";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {Locale} from "../../common/utils/Locale";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {TranslationPortalFile} from "../../utils/constants";
import {CmpWebinarInfo} from "../../api/model/cmp/webinar/CmpWebinarInfo";
import {CmpWebinarFaqItem} from "../../api/model/cmp/webinar/CmpWebinarFaqInfo";
import {handleCatchError} from "../../common/utils/helper";

const WEBINAR_GOOGLE_ERRORS_ID = 2;

function GoogleErrors() {
    const {t: textGoogleErrors} = useTranslation(TranslationPortalFile.GOOGLE_ERRORS);
    const [webinarInfo, setWebinarInfo] = useState<CmpWebinarInfo>();
    const [webinarFaqItems, setWebinarFaqItems] = useState<CmpWebinarFaqItem[]>();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const webinar = await session.restPortalCmp.getWebinar(WEBINAR_GOOGLE_ERRORS_ID);
                const language = Locale.getCurrentLocale();

                setWebinarInfo(webinar.info?.get(language) || webinar.info?.get(Locale.FRENCH));
                setWebinarFaqItems(webinar.faq?.get(language) || webinar.faq?.get(Locale.FRENCH));
            } catch (e) {
                handleCatchError(e, (e) => {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    }
                    console.error("Failed to load data", e.message);
                });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent cssClass="google-errors">
                <MainContentPageHeader
                    title={textGoogleErrors("title")}
                    description={textGoogleErrors("description")}
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <Tabs
                            headers={[
                                {label: textGoogleErrors("tab.WEBINAR"), icon: {name: "play_circle", outlined: true}},
                                {label: textGoogleErrors("tab.FLOW_CHART"), icon: {name: "account_tree", outlined: true}},
                                {label: textGoogleErrors("tab.FAQ"), icon: {name: "quiz", outlined: true}}
                            ]}
                        >
                            <div className="iframe-container">
                                <iframe
                                    src={"https://www.youtube.com/embed/" + webinarInfo?.youtube_id}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={webinarInfo?.title}
                                />
                            </div>
                            <div className="flow-chart">
                                <ReactFlow
                                    defaultNodes={Locale.isFrench() ? initialElementsFR.nodes : initialElementsEN.nodes}
                                    defaultEdges={Locale.isFrench() ? initialElementsFR.edges : initialElementsEN.edges}
                                    defaultPosition={[0, 0]}
                                    defaultZoom={.85}
                                    snapToGrid={true}
                                    zoomOnScroll={false}
                                    nodesDraggable={false}
                                >
                                    <Controls style={{top: "10px", bottom: "auto"}} showInteractive={false}/>
                                </ReactFlow>
                            </div>
                            {!!webinarFaqItems?.length &&
                            <Accordion cssClass="faq-accordion">
                                {webinarFaqItems?.map(({question, answer}, index) =>
                                    <AccordionItem key={`question-${index + 1}`} heading={`${index + 1}. ${question}`}>
                                        <AccordionItemContent>
                                            <span dangerouslySetInnerHTML={{__html: answer}}/>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                )}
                            </Accordion>
                            }
                        </Tabs>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default GoogleErrors;
