import {FunctionComponent, useEffect, useState} from "react";
import {Box, BoxProps, BoxRadius, ContentBlock, LayoutColumns, LayoutRows} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {ChartContainer, ChartDoughnut, ChartLine} from "../../widget";
import {CHART_DATASET_CONSENT_STYLES, CHART_DATASET_NO_CHOICE_STYLES, CHART_DATASET_OBJECT_STYLES, GraphData, optionsRating} from "../../../utils/stat/chart_options";
import {ChartCardLegend, ChartCardLegendLabels, MetricCard} from "../index";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {TranslationPortalFile} from "../../../utils/constants";
import {AnalyticsColor} from "../../../utils/AnalyticsColor";
import {convertRateToPercent} from "../../../utils/stat/consent/stats";
import {StatConsentRates} from "../../../api/model/stat/consent/StatConsentRates";

type AnalyticsConsentDetailsDeviceProps = {
    dates: string[];
    deviceRates: StatConsentRates;
    period: StatPeriod;
};

const AnalyticsConsentDetailsDevice: FunctionComponent<AnalyticsConsentDetailsDeviceProps> = ({dates, deviceRates, period}) => {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const [rateGraphData, setRateGraphData] = useState<GraphData>();
    const [responseGraphData, setResponseGraphData] = useState<GraphData>();
    const [clickGraphData, setClickGraphData] = useState<GraphData>();

    useEffect(() => {
        setRateGraphData({
            labels: dates,
            datasets: [{
                label: textAnalytics("metric.consent"),
                data: deviceRates.daily.consent,
                ...CHART_DATASET_CONSENT_STYLES
            }, {
                label: textAnalytics("metric.object"),
                data: deviceRates.daily.object,
                ...CHART_DATASET_OBJECT_STYLES
            }, {
                label: textAnalytics("metric.no_choice"),
                data: deviceRates.daily.no_choice,
                ...CHART_DATASET_NO_CHOICE_STYLES
            }]
        });

        setResponseGraphData({
            labels: [
                textAnalytics("legend.accept"),
                textAnalytics("legend.reject"),
                textAnalytics("legend.close"),
                textAnalytics("legend.ask_later"),
                textAnalytics("legend.customized_choices")
            ],
            datasets: [{
                borderWidth: 2,
                borderColor: "#FFFFFF",
                backgroundColor: [
                    AnalyticsColor.CONSENT,
                    AnalyticsColor.OBJECT,
                    AnalyticsColor.CLOSE,
                    AnalyticsColor.ASK_LATER,
                    AnalyticsColor.SAVE
                ],
                data: [
                    convertRateToPercent(deviceRates.responses["accept"] / deviceRates.getTotalResponses()),
                    convertRateToPercent(deviceRates.responses["reject"] / deviceRates.getTotalResponses()),
                    convertRateToPercent(deviceRates.responses["close"] / deviceRates.getTotalResponses()),
                    convertRateToPercent(deviceRates.responses["askLater"] / deviceRates.getTotalResponses()),
                    convertRateToPercent(deviceRates.responses["save"] / deviceRates.getTotalResponses())
                ]
            }]
        });

        setClickGraphData({
            labels: [
                textAnalytics("legend.accept_all"),
                textAnalytics("legend.reject_all"),
                textAnalytics("legend.close"),
                textAnalytics("legend.ask_later"),
                textAnalytics("legend.continue"),
                textAnalytics("legend.save"),
                textAnalytics("legend.set_choices"),
                textAnalytics("legend.our_partners"),
                textAnalytics("legend.see_purposes"),
                textAnalytics("legend.see_partners"),
                textAnalytics("legend.sites")
            ],
            datasets: [{
                borderWidth: 2,
                borderColor: "#FFFFFF",
                backgroundColor: [
                    AnalyticsColor.CONSENT,
                    AnalyticsColor.OBJECT,
                    AnalyticsColor.CLOSE,
                    AnalyticsColor.ASK_LATER,
                    AnalyticsColor.CONTINUE,
                    AnalyticsColor.SAVE,
                    AnalyticsColor.PURPOSES,
                    AnalyticsColor.PARTNERS,
                    AnalyticsColor.SEE_PURPOSES,
                    AnalyticsColor.SEE_PARTNERS,
                    AnalyticsColor.SITES
                ],
                data: [
                    convertRateToPercent(deviceRates.clicks["accept"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["reject"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["close"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["askLater"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["continue"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["save"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["purposes"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["vendors"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["see_purposes"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["see_vendors"] / deviceRates.getTotalClicks()),
                    convertRateToPercent(deviceRates.clicks["sites"] / deviceRates.getTotalClicks())
                ]
            }]
        });
    }, [deviceRates, dates, period, textAnalytics]);

    return (
        <LayoutRows>
            <LayoutColumns>
                <MetricCard
                    header={{label: textAnalytics("metric.consent")}}
                    value={`${deviceRates.ratings.consent}%`}
                    trendValue={deviceRates.trends.consent}
                    showTrend={period.name !== StatPeriod.CUSTOM.name}
                />
                <MetricCard
                    header={{label: textAnalytics("metric.object")}}
                    value={`${deviceRates.ratings.object}%`}
                    trendValue={deviceRates.trends.object}
                    showTrend={period.name !== StatPeriod.CUSTOM.name}
                />
                <MetricCard
                    header={{label: textAnalytics("metric.no_choice")}}
                    value={`${deviceRates.ratings.no_choice}%`}
                    trendValue={deviceRates.trends.no_choice}
                    showTrend={period.name !== StatPeriod.CUSTOM.name}
                />
            </LayoutColumns>
            <ContentBlock header={{title: {label: textAnalytics("evolution_by_rating")}}}>
                <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass="chart-card">
                    <ChartCardLegend
                        labels={rateGraphData?.datasets.map((dataset) => ({value: dataset.label, color: dataset.borderColor})) as ChartCardLegendLabels[]}
                        alignRight
                    />
                    <ChartContainer height="35rem">
                        <ChartLine data={rateGraphData} options={optionsRating}/>
                    </ChartContainer>
                </Box>
            </ContentBlock>
            <LayoutColumns>
                <ContentBlock header={{title: {label: textAnalytics("choices_distribution")}}} cssClass="col-left">
                    <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass="chart-card">
                        <ChartContainer height="20rem">
                            <ChartDoughnut data={responseGraphData}/>
                        </ChartContainer>
                        <ChartCardLegend
                            labels={responseGraphData?.labels.reduce((labels, currentLabel, index) => {
                                if (responseGraphData?.datasets[0].data[index] > 0) {
                                    labels.push({value: currentLabel, color: responseGraphData?.datasets[0].backgroundColor[index]});
                                }
                                return labels;
                            }, [] as ChartCardLegendLabels[])}
                        />
                    </Box>
                </ContentBlock>
                <ContentBlock header={{title: {label: textAnalytics("clicks_distribution")}}} cssClass="col-right">
                    <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass="chart-card">
                        <ChartContainer height="20rem">
                            <ChartDoughnut data={clickGraphData}/>
                        </ChartContainer>
                        <ChartCardLegend
                            labels={clickGraphData?.labels.reduce((labels, currentLabel, index) => {
                                if (clickGraphData?.datasets[0].data[index] > 0) {
                                    labels.push({value: currentLabel, color: clickGraphData?.datasets[0].backgroundColor[index]});
                                }
                                return labels;
                            }, [] as ChartCardLegendLabels[])}
                        />
                    </Box>
                </ContentBlock>
            </LayoutColumns>
        </LayoutRows>
    );
};

export default AnalyticsConsentDetailsDevice;
