import {useEffect, useState} from "react";
import {
    Alert,
    AlertSeverity,
    Button,
    ButtonAdd,
    ButtonSize,
    Color,
    ContentBlock,
    EmptyContentMessage,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    SearchError,
    SearchToolbar,
    SvgUnderConstruction,
    Table,
    TableColumnStyle
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import CmpUpgradeTransition from "../../utils/CmpUpgradeTransition";
import ModalConfigAdvancedInfo, {ModalConfigAdvancedInfoArgs, ModalConfigAdvancedInfoUiEvent} from "../../component/modal/ModalConfigAdvancedInfo";
import ModalConfigMode from "../../component/modal/ModalConfigMode";
import ModalConfigFlashInfo from "../../component/modal/ModalConfigFlashInfo";
import ModalConfigCustomInfo from "../../component/modal/ModalConfigCustomInfo";
import ModalDeleteConfig from "../../component/modal/ModalDeleteConfig";
import {CONFIGURATION_MODES, ConfigurationModeName} from "../../utils/ConfigurationMode";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigStatus} from "../../api/model/cmp/config/CmpConfigStatus";
import {CmpRow} from "../../component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {PAGE_SIZE, pathSitesConfigurations, TranslationPortalFile} from "../../utils/constants";
import {PortalQueryString} from "../../api/model/PortalQueryString";
import {session} from "../../api/ApiSession";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigPropertiesCreationType} from "../../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {CmpConfigPropertiesField} from "../../api/model/cmp/config/CmpConfigPropertiesField";
import {sortItems} from "../../common/utils/helper";

function SitesConfigurations() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentCmpConfigs, setCurrentCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentDeleteCmp, setCurrentDeleteCmp] = useState<CmpConfig>();
    const [query, setQuery] = useState("");

    const [isLoading, setLoading] = useState(true);
    const [isShowModalConfigFlashInfo, setShowModalConfigFlashInfo] = useState(false);
    const [isShowModalConfigCustomInfo, setShowModalConfigCustomInfo] = useState(false);
    const [isShowModalConfigMode, setShowModalConfigMode] = useState(false);

    const [hasOutdatedConfig, setHasOutdatedConfig] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await session.loadAccount();

                let cmpConfigs = await session.restCmpConfig.list();
                cmpConfigs = sortItems(cmpConfigs, CmpConfigField.CREATION_TIME, true);
                setCmpConfigs(cmpConfigs);
                setCurrentCmpConfigs(cmpConfigs);

                switch (true) {
                    case location.state?.mode === ConfigurationModeName.CUSTOM:
                        setShowModalConfigCustomInfo(true);
                        navigate(pathSitesConfigurations, {replace: true});
                        break;
                    case location.state?.mode === ConfigurationModeName.ADVANCED:
                        UIEventManager.emit(ModalConfigAdvancedInfoUiEvent, {
                            onClose: () => navigate(pathSitesConfigurations)
                        } as ModalConfigAdvancedInfoArgs);
                        navigate(pathSitesConfigurations, {replace: true});
                        break;
                    case !!PortalQueryString.getParam(location.search, PortalQueryString.ID):
                        UIEventManager.emit(ModalConfigAdvancedInfoUiEvent, {
                            cmpConfigId: PortalQueryString.getParam(location.search, PortalQueryString.ID),
                            onClose: () => navigate(pathSitesConfigurations)
                        } as ModalConfigAdvancedInfoArgs);
                        break;
                    default:
                        break;
                }

                setHasOutdatedConfig(await session.hasOutdatedConfig());
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [location, navigate]);

    useEffect(() => {
        const newCurrentCmpConfigs = cmpConfigs.filter((item) => !query || `${item.name} ${item.domain}`.toLowerCase().includes(query.toLowerCase()));
        setCurrentCmpConfigs(newCurrentCmpConfigs);
    }, [query, cmpConfigs]);


    const handleChangeSortOrder = (field: string, reverseOrder?: boolean) => {
        setCmpConfigs((prevState) => sortItems(prevState, field, reverseOrder));
    };

    const handleAdd = () => {
        setShowModalConfigMode(true);
    };

    const handleDuplicate = async (cmpConfig: CmpConfig) => {
        try {
            const cmpConfigToDuplicate = await session.restCmpConfig.get(cmpConfig.id);

            const newCmpConfig = new CmpConfig({
                ...cmpConfigToDuplicate,
                [CmpConfigField.ID]: "",
                [CmpConfigField.NAME]: `${cmpConfigToDuplicate.name} (${textConfiguration("copy")})`,
                [CmpConfigField.PROPERTIES]: {
                    [CmpConfigPropertiesField.CREATION_TYPE]: CmpConfigPropertiesCreationType.DUPLICATE
                }
            });

            const createdCmpConfig = await session.restCmpConfig.create(newCmpConfig);
            const newCmpConfigs = [createdCmpConfig, ...cmpConfigs];
            setCmpConfigs(newCmpConfigs);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.duplicate_cmp"), AlertSeverity.DANGER);
        }
    };

    const handleToggleStatus = async (cmpConfig: CmpConfig) => {
        try {
            const cmpConfigToUpdate = await session.restCmpConfig.get(cmpConfig.id);
            cmpConfigToUpdate.status = cmpConfigToUpdate.status === CmpConfigStatus.ACTIVE ? CmpConfigStatus.INACTIVE : CmpConfigStatus.ACTIVE;
            const updatedCmpConfig = await session.restCmpConfig.update(cmpConfigToUpdate);
            const newCmpConfigs = [...cmpConfigs];
            newCmpConfigs.splice(cmpConfigs.findIndex((item) => item.id === cmpConfig.id), 1, updatedCmpConfig);
            setCmpConfigs(newCmpConfigs);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp_status"), AlertSeverity.DANGER);
        }
    };

    const handleDelete = async (cmpConfig: CmpConfig) => {
        if (!cmpConfig) return;
        try {
            await session.restCmpConfig.delete(cmpConfig.id);
            const newCmpConfigs = [...cmpConfigs];
            newCmpConfigs.splice(newCmpConfigs.findIndex((item) => item.id === cmpConfig.id), 1);
            setCmpConfigs(newCmpConfigs);
            setCurrentDeleteCmp(undefined);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.delete_cmp"), AlertSeverity.DANGER);
        }
    };

    const handleConfigModeChoice = (mode: ConfigurationModeName) => {
        switch (mode) {
            case ConfigurationModeName.ADVANCED:
                UIEventManager.emit(ModalConfigAdvancedInfoUiEvent, {
                    onClose: () => navigate(pathSitesConfigurations)
                } as ModalConfigAdvancedInfoArgs);
                break;
            case ConfigurationModeName.FLASH:
                setShowModalConfigFlashInfo(true);
                break;
            case ConfigurationModeName.CUSTOM:
                setShowModalConfigCustomInfo(true);
                break;
            default:
                break;
        }
        setShowModalConfigMode(false);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textConfiguration("title")} icon={{name: "settings", colorIcon: Color.CYAN}}/>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        {hasOutdatedConfig && <Alert text={t("list.update_alert", {version: CmpUpgradeTransition.newVersion.label})}/>}
                        {!!cmpConfigs.length ?
                            <ContentBlock>
                                <SearchToolbar
                                    searchBar={{placeholder: textConfiguration("search.site"), value: query, onChange: setQuery}}
                                    actions={[<ButtonAdd key={0} size={ButtonSize.MEDIUM} onClick={handleAdd}/>]}
                                />
                                {!!currentCmpConfigs.length ?
                                    <Table
                                        columns={[
                                            {width: 10, label: textConfiguration(`field.${CmpConfigField.STATUS}`)},
                                            {width: 10, label: textConfiguration(`field.${CmpConfigField.ID}`)},
                                            {width: 30, label: textConfiguration(`field.${CmpConfigField.NAME}`)},
                                            {width: 30, label: textConfiguration(`field.${CmpConfigField.DOMAIN}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {width: 10, label: textConfiguration(`field.${CmpConfigField.CREATION_TIME}`), sort: {field: CmpConfigField.CREATION_TIME}},
                                            {width: 10, styles: TableColumnStyle.ALIGN_CENTER}
                                        ]}
                                        pagination={PAGE_SIZE}
                                        onSort={handleChangeSortOrder}
                                    >
                                        {currentCmpConfigs.map((item: CmpConfig) =>
                                            <CmpRow
                                                key={item.id}
                                                cmp={item}
                                                onPublish={() => handleToggleStatus(item)}
                                                onCopy={() => handleDuplicate(item)}
                                                onDelete={() => setCurrentDeleteCmp(item)}
                                            />)
                                        }
                                    </Table> :
                                    <SearchError query={query}/>
                                }
                            </ContentBlock> :
                            <EmptyContentMessage svg={SvgUnderConstruction} message={textConfiguration("empty_content.message")}>
                                <Button onClick={handleAdd} size={ButtonSize.BIG}>
                                    {textConfiguration("empty_content.start")}
                                </Button>
                            </EmptyContentMessage>
                        }
                    </Loadable>
                </LayoutRows>
                <ModalConfigMode
                    active={isShowModalConfigMode}
                    modes={CONFIGURATION_MODES}
                    onClose={() => setShowModalConfigMode(false)}
                    onClick={handleConfigModeChoice}
                />
                <ModalConfigAdvancedInfo/>
                <ModalConfigFlashInfo
                    active={isShowModalConfigFlashInfo}
                    onClose={() => setShowModalConfigFlashInfo(false)}
                />
                <ModalConfigCustomInfo
                    active={isShowModalConfigCustomInfo}
                    onClose={() => setShowModalConfigCustomInfo(false)}
                />
                <ModalDeleteConfig
                    cmpConfig={currentDeleteCmp}
                    onClose={() => setCurrentDeleteCmp(undefined)}
                    onDelete={() => handleDelete(currentDeleteCmp!)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default SitesConfigurations;
