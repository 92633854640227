import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {pathDelegatedSitesConfigurations, pathSitesConfigurations, TranslationPortalFile} from "../../../utils/constants";
import {Action, Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, MainHeaderSubLayout, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalNew, SplitButton, TranslationLibFile, TranslationLibKey} from "@sirdata/ui-lib";
import {ConfigHeaderMenu, ProgressBar} from "../index";
import CmpConfigStepper, {CmpConfigStepName} from "../../../utils/CmpConfigStepper";

type ConfigHeaderCreateProps = {
    step: CmpConfigStepName;
    delegatedMode: boolean;
    hasUnsavedChanges: boolean;
    onSave: () => Promise<void>;
    onOpenPreview?: () => void;
}

const ConfigHeaderCreate: FunctionComponent<ConfigHeaderCreateProps> = ({step, delegatedMode, hasUnsavedChanges, onSave, onOpenPreview = () => {}}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const navigate = useNavigate();
    const {id: configId} = useParams() as {id: string};

    const [isShowCreateWarning, setShowCreateWarning] = useState(false);
    const [shouldConfirmBeforeQuit, setShouldConfirmBeforeQuit] = useState(hasUnsavedChanges);
    const [goToPath, setGoToPath] = useState("");

    const handleSaveAndGoTo = async (path: string) => {
        await onSave();
        navigate(path);
    };

    const handleGoTo = (path: string) => {
        if (shouldConfirmBeforeQuit) {
            setGoToPath(path);
            setShowCreateWarning(true);
        } else {
            navigate(path);
        }
    };

    const handleGoBackToSites = () => {
        handleSaveAndGoTo(delegatedMode ? pathDelegatedSitesConfigurations : pathSitesConfigurations);
    };

    useEffect(() => {
        setShouldConfirmBeforeQuit(hasUnsavedChanges);
    }, [hasUnsavedChanges]);

    return (
        <MainHeaderSubLayout cssClass="config-header">
            {step === CmpConfigStepName.INTEGRATION ?
                <>
                    <ConfigHeaderMenu step={step} onChangePath={handleGoTo}/>
                    <div className="config-header__buttons">
                        <SplitButton
                            size={ButtonSize.SMALL}
                            style={ButtonStyle.PRIMARY_WHITE}
                            actions={[
                                {label: textConfiguration("action.back_to_sites"), onClick: handleGoBackToSites},
                                {label: textConfiguration("action.preview"), onClick: onOpenPreview},
                                {label: textCommon(Action.BACK.labelKey), onClick: () => handleGoTo(CmpConfigStepper.getPreviousStepPath(step, configId))}
                            ]}
                        />
                        <div className="inline-buttons">
                            <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_WHITE} icon={{name: "visibility"}} onClick={onOpenPreview}>
                                {textConfiguration("action.preview")}
                            </Button>
                            <Button
                                size={ButtonSize.SMALL}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                icon={{name: "keyboard_arrow_right"}}
                                reverse
                                onClick={handleGoBackToSites}
                            >
                                {textConfiguration("action.back_to_sites")}
                            </Button>
                        </div>
                    </div>
                </> :
                <>
                    <Button size={ButtonSize.SMALL} icon={{name: "chevron_left"}} style={ButtonStyle.PRIMARY_WHITE} onClick={() => handleGoTo(CmpConfigStepper.getPreviousStepPath(step, configId))}>
                        {textCommon(Action.BACK.labelKey)}
                    </Button>
                    <div className="config-header__buttons">
                        <SplitButton
                            size={ButtonSize.SMALL}
                            style={ButtonStyle.PRIMARY_GREEN}
                            actions={[
                                {label: textConfiguration("action.save_continue"), onClick: () => handleSaveAndGoTo(CmpConfigStepper.getNextStepPath(step, configId))},
                                {label: textConfiguration("action.save_later"), onClick: handleGoBackToSites}
                            ]}
                        />
                        <div className="inline-buttons">
                            <Button size={ButtonSize.SMALL} icon={Action.SAVE.icon} style={ButtonStyle.PRIMARY_WHITE} onClick={handleGoBackToSites}>
                                {textConfiguration("action.save_later")}
                            </Button>
                            <Button
                                size={ButtonSize.SMALL}
                                icon={{name: "keyboard_arrow_right"}}
                                reverse
                                style={ButtonStyle.PRIMARY_GREEN}
                                onClick={() => handleSaveAndGoTo(CmpConfigStepper.getNextStepPath(step, configId))}
                            >
                                {textConfiguration("action.save_continue")}
                            </Button>
                        </div>
                    </div>
                    <ProgressBar step={CmpConfigStepper.getStepPosition(step)} totalSteps={CmpConfigStepper.steps.length}/>
                </>
            }
            <ModalNew active={isShowCreateWarning} onClose={() => setShowCreateWarning(false)}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textConfiguration("message.warning_create")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => navigate(goToPath || CmpConfigStepper.getPreviousStepPath(step, configId))}>
                                {textCommon(TranslationLibKey.ACTION_QUIT_WITHOUT_SAVING)}
                            </Button>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => handleSaveAndGoTo(goToPath || CmpConfigStepper.getPreviousStepPath(step, configId))}>
                                {textCommon(TranslationLibKey.ACTION_SAVE_AND_QUIT)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </MainHeaderSubLayout>
    );
};

export default ConfigHeaderCreate;
