export enum CmpConfigField {
    ID = "id",
    ID_PARTNER = "id_partner",
    NAME = "name",
    VERSION = "version",
    SETTINGS = "settings",
    CONFIGURATION = "configuration",
    DOMAIN = "domain",
    STATUS = "status",
    STEP = "step",
    CREATION_TIME = "creation_time",
    LAST_UPDATE = "last_update",
    PROPERTIES = "properties",
    DELEGATED_PARTNER_COMPANY = "delegated_partner_company"
}
