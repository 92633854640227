import {Action, Color, IconTooltip, MainContentPageHeader, MainContentStyle, Table, TableColumn, TableColumnStyle, TableRow, Tag, TagSize, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {Document} from "../utils/documentation/Document";
import {DocumentActionType} from "../utils/documentation/DocumentActionType";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {TranslationPortalFile} from "../utils/constants";

const Documentation = () => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDocumentation} = useTranslation(TranslationPortalFile.DOCUMENTATION);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    icon={{name: "description", colorIcon: Color.CYAN}}
                    title={textDocumentation("title")}
                    description={textDocumentation("subtitle")}
                />
                <Table
                    columns={[
                        {width: 60, label: textDocumentation("list.name")},
                        {width: 35, label: textDocumentation("list.type")},
                        {width: 5, styles: TableColumnStyle.ALIGN_CENTER}
                    ]}
                >
                    {Document.values().map((document) =>
                        <TableRow key={document.labelKey}>
                            <TableColumn>
                                {textDocumentation(`document.${document.labelKey}`)}
                            </TableColumn>
                            <TableColumn>
                                <Tag label={textDocumentation(`type.${document.type}`)} size={TagSize.SMALL} style={TagStyle.PRIMARY_OCEAN}/>
                            </TableColumn>
                            <TableColumn>
                                {document.action.type === DocumentActionType.DOWNLOAD ? (
                                    <IconTooltip icon={Action.DOWNLOAD.icon} text={textCommon(Action.DOWNLOAD.labelKey)} onClick={() => document.download(textDocumentation(`document.${document.labelKey}`))}/>
                                ) : (
                                    <IconTooltip icon={Action.OPEN.icon} text={textCommon(Action.OPEN.labelKey)} onClick={() => window.open(document.action.src, "_blank")}/>
                                )}
                            </TableColumn>
                        </TableRow>)
                    }
                </Table>
            </MainContent>
        </Wrapper>
    );
};

export default Documentation;
