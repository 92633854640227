import {complianceAssistanceDocumentationSrc, sirdataDocumentationUrl} from "../helper";
import {DocumentAction} from "./DocumentAction";
import {DocumentActionType} from "./DocumentActionType";
import {DocumentType} from "./DocumentType";

export class Document {
    labelKey: string = "";
    type: DocumentType = DocumentType.TECHNICAL;
    action: DocumentAction;

    static TECHNICAL_DOCUMENTATION = new Document("technical_documentation", DocumentType.TECHNICAL, {type: DocumentActionType.REDIRECT, src: sirdataDocumentationUrl});
    static COMPLIANCE_ASSISTANCE = new Document("compliance_assistance", DocumentType.LEGAL, {type: DocumentActionType.DOWNLOAD, src: complianceAssistanceDocumentationSrc});

    constructor(labelKey: string, type: DocumentType, action: DocumentAction) {
        this.labelKey = labelKey;
        this.type = type;
        this.action = action;
    }

    static values(): Document[] {
        return [
            Document.TECHNICAL_DOCUMENTATION,
            Document.COMPLIANCE_ASSISTANCE
        ];
    }

    download = (fileName: string) => {
        const link = document.createElement("a");
        link.href = this.action.src;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
}
