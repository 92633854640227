import {pathHome, routeParamId} from "../common/utils/constants";

export const pathIndex = "/";

export const pathBarterAgreement = "/barter-agreement";
export const pathDocumentation = "/documentation";
export const pathGoogleErrors = "/google-errors";
export const pathTermsOfSale = "/terms-of-sale";
export const pathTermsOfUse = "/terms-of-use";

export const pathSites = "/sites";
export const pathSitesConfigurations = pathSites + "/configurations";
export const pathSitesAnalytics = pathSites + "/analytics";
export const pathSitesAnalyticsConsent = pathSitesAnalytics + "/consent";
export const pathSitesAnalyticsConsentWithId = pathSitesAnalyticsConsent + routeParamId;
export const pathSitesAnalyticsHits = pathSitesAnalytics + "/hits";
export const pathSitesAnalyticsHitsWithId = pathSitesAnalyticsHits + routeParamId;

export const pathDelegatedSites = "/delegated-sites";
export const pathDelegatedSitesConfigurations = pathDelegatedSites + "/configurations";
export const pathDelegatedSitesAnalytics = pathDelegatedSites + "/analytics";
export const pathDelegatedSitesAnalyticsConsent = pathDelegatedSitesAnalytics + "/consent";
export const pathDelegatedSitesAnalyticsConsentWithId = pathDelegatedSitesAnalyticsConsent + routeParamId;
export const pathDelegatedSitesAnalyticsHits = pathDelegatedSitesAnalytics + "/hits";
export const pathDelegatedSitesAnalyticsHitsWithId = pathDelegatedSitesAnalyticsHits + routeParamId;

export const pathConfiguration = "/configuration";
const pathConfigurationWithId = pathConfiguration + routeParamId;
export const pathConfigWithIdAddOns = pathConfigurationWithId + "/add-ons";
export const pathConfigWithIdBanner = pathConfigurationWithId + "/banner";
export const pathConfigWithIdImages = pathConfigurationWithId + "/images";
export const pathConfigWithIdSettings = pathConfigurationWithId + "/settings";
export const pathConfigWithIdPartners = pathConfigurationWithId + "/partners";
export const pathConfigWithIdPurposes = pathConfigurationWithId + "/purposes";
export const pathConfigWithIdIntegration = pathConfigurationWithId + "/integration";

/* FUNNEL */
export const pathConfigurationFlashInformation = "/configuration-flash-information";
export const pathConfigurationFlashPreview = "/configuration-flash-preview";
export const pathConfigurationMode = "/configuration-mode";

export const DEFAULT_PATH = pathHome;

/* TRANSLATIONS */
export enum TranslationPortalFile {
    ACCESS = "access",
    ANALYTICS = "analytics",
    AUDIT = "audit",
    BARTER_AGREEMENT = "barter-agreement",
    CONFIG_ADD_ONS = "config-add-ons",
    CONFIG_BANNER = "config-banner",
    CONFIG_IMAGES = "config-images",
    CONFIG_INTEGRATION = "config-integration",
    CONFIG_PARTNERS = "config-partners",
    CONFIG_PURPOSES = "config-purposes",
    CONFIG_SETTINGS = "config-settings",
    CONFIGURATION = "configuration",
    CONFIGURATION_FLASH = "configuration-flash",
    CONFIGURATION_MODE = "configuration-mode",
    CROSS_SALES = "cross-sales",
    DOCUMENTATION = "documentation",
    GOOGLE_ERRORS = "google-errors",
    HOME = "home",
    LEGAL_NOTICE = "legal-notice",
    LOGIN = "login",
    PREVIEW = "preview",
    SUPPORT = "support",
    TERMS_OF_SALE = "terms-of-sale",
    TRANSLATION = "translation"
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.ACCESS,
    TranslationPortalFile.ANALYTICS,
    TranslationPortalFile.AUDIT,
    TranslationPortalFile.BARTER_AGREEMENT,
    TranslationPortalFile.CONFIG_ADD_ONS,
    TranslationPortalFile.CONFIG_BANNER,
    TranslationPortalFile.CONFIG_IMAGES,
    TranslationPortalFile.CONFIG_INTEGRATION,
    TranslationPortalFile.CONFIG_PARTNERS,
    TranslationPortalFile.CONFIG_PURPOSES,
    TranslationPortalFile.CONFIG_SETTINGS,
    TranslationPortalFile.CONFIGURATION,
    TranslationPortalFile.CONFIGURATION_FLASH,
    TranslationPortalFile.CONFIGURATION_MODE,
    TranslationPortalFile.CROSS_SALES,
    TranslationPortalFile.DOCUMENTATION,
    TranslationPortalFile.GOOGLE_ERRORS,
    TranslationPortalFile.HOME,
    TranslationPortalFile.LEGAL_NOTICE,
    TranslationPortalFile.LOGIN,
    TranslationPortalFile.PREVIEW,
    TranslationPortalFile.SUPPORT,
    TranslationPortalFile.TERMS_OF_SALE,
    TranslationPortalFile.TRANSLATION
];

export const PAGE_SIZE = 20;
export const MODAL_PAGE_SIZE = 10;
