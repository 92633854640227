import {FunctionComponent} from "react";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigStepName} from "../../../utils/CmpConfigStepper";
import {ConfigHeaderCreate, ConfigHeaderEdit} from "../index";

type ConfigHeaderProps = {
    step: CmpConfigStepName;
    editMode: boolean;
    hasUnsavedChanges: boolean;
    onSave: () => Promise<void>;
    onOpenPreview?: () => void;
    delegatedMode?: boolean;
    cmpConfig?: CmpConfig;
};

const ConfigHeader: FunctionComponent<ConfigHeaderProps> = ({step, editMode, hasUnsavedChanges, onSave, onOpenPreview, delegatedMode = false, cmpConfig}) => {
    return (
        editMode ?
            <ConfigHeaderEdit
                step={step}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={onSave}
                onOpenPreview={onOpenPreview}
                delegatedMode={delegatedMode}
                cmpConfig={cmpConfig}
            /> :
            <ConfigHeaderCreate
                step={step}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={onSave}
                onOpenPreview={onOpenPreview}
                delegatedMode={delegatedMode}
            />
    );
};

export default ConfigHeader;
