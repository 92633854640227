import {Rest} from "../../common/api/rest/Rest";
import {CmpConfig} from "../model/cmp/config/CmpConfig";
import {CmpConfigUploadImageResponse} from "../model/cmp/config/CmpConfigUploadImageResponse";
import {Model} from "../../common/api/interface/Model";
import {CmpConfigImageSelection} from "../../utils/CmpConfigImageSelection";
import {CmpConfigFilters} from "../model/cmp/config/CmpConfigFilters";
import {CmpConfigSearchQuery} from "../model/cmp/config/CmpConfigSearchQuery";

export class RestCmpConfig extends Rest {
    rootPath = "/cmp-api/partner/config";

    async list(searchQuery?: CmpConfigSearchQuery): Promise<CmpConfig[]> {
        return this._client.get(`${this.rootPath}`, CmpConfig, {queryParams: searchQuery?.getJsonParameters()}) as Promise<CmpConfig[]>;
    }

    async get(configId: string): Promise<CmpConfig> {
        return this._client.get(`${this.rootPath}/${configId}`, CmpConfig) as Promise<CmpConfig>;
    }

    async create(cmpConfig: CmpConfig, partnerId?: number): Promise<CmpConfig> {
        return this._client.post(`${this.rootPath}${partnerId ? ("?partner_id=" + partnerId) : ""}`, cmpConfig, CmpConfig) as Promise<CmpConfig>;
    }

    async update(cmpConfig: CmpConfig): Promise<CmpConfig> {
        return this._client.put(`${this.rootPath}/${cmpConfig.id}`, cmpConfig, CmpConfig) as Promise<CmpConfig>;
    }

    async uploadImage(file: File): Promise<CmpConfigUploadImageResponse> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        return this._client.post(`${this.rootPath}/image`, formData, CmpConfigUploadImageResponse) as Promise<CmpConfigUploadImageResponse>;
    }

    async delete(configId: string): Promise<Model> {
        return this._client.delete(`${this.rootPath}/${configId}`);
    }

    async getFilters(): Promise<CmpConfigFilters> {
        return this._client.get(`${this.rootPath}/filter`, CmpConfigFilters) as Promise<CmpConfigFilters>;
    }

    async getImageSelection(): Promise<CmpConfigImageSelection> {
        return this._client.get(`${this.rootPath}/theme`, CmpConfigImageSelection) as Promise<CmpConfigImageSelection>;
    }
}
