import {pathSitesConfigurations, pathConfigWithIdAddOns, pathConfigWithIdBanner, pathConfigWithIdImages, pathConfigWithIdIntegration, pathConfigWithIdPartners, pathConfigWithIdPurposes, pathConfigWithIdSettings, pathDelegatedSitesConfigurations} from "./constants";
import {PortalQueryString} from "../api/model/PortalQueryString";
import {PathHelper} from "../common/utils/PathHelper";

export enum CmpConfigStepName {
    INFO = "INFO",
    BANNER = "BANNER",
    IMAGES = "IMAGES",
    SETTINGS = "SETTINGS",
    ADD_ONS = "ADD_ONS",
    PARTNERS = "PARTNERS",
    PURPOSES = "PURPOSES",
    INTEGRATION = "INTEGRATION"
}

export class CmpConfigStep {
    name: CmpConfigStepName;
    path: string = "";

    constructor(name: CmpConfigStepName, path: string) {
        this.name = name;
        this.path = path;
    }

    getPath(cmpId: string): string {
        if (this.name === CmpConfigStepName.INFO) {
            return `${this.path}?${PortalQueryString.ID}=${cmpId}`;
        }
        return PathHelper.buildPathWithId(this.path, cmpId);
    }
}

class CmpConfigStepper {
    static DEFAULT: CmpConfigStepper = new CmpConfigStepper(
        new CmpConfigStep(CmpConfigStepName.INFO, pathSitesConfigurations),
        new CmpConfigStep(CmpConfigStepName.BANNER, pathConfigWithIdBanner),
        new CmpConfigStep(CmpConfigStepName.IMAGES, pathConfigWithIdImages),
        new CmpConfigStep(CmpConfigStepName.SETTINGS, pathConfigWithIdSettings),
        new CmpConfigStep(CmpConfigStepName.ADD_ONS, pathConfigWithIdAddOns),
        new CmpConfigStep(CmpConfigStepName.PARTNERS, pathConfigWithIdPartners),
        new CmpConfigStep(CmpConfigStepName.PURPOSES, pathConfigWithIdPurposes),
        new CmpConfigStep(CmpConfigStepName.INTEGRATION, pathConfigWithIdIntegration)
    );

    static DELEGATED: CmpConfigStepper = new CmpConfigStepper(
        new CmpConfigStep(CmpConfigStepName.INFO, pathDelegatedSitesConfigurations),
        new CmpConfigStep(CmpConfigStepName.BANNER, pathConfigWithIdBanner),
        new CmpConfigStep(CmpConfigStepName.IMAGES, pathConfigWithIdImages),
        new CmpConfigStep(CmpConfigStepName.SETTINGS, pathConfigWithIdSettings),
        new CmpConfigStep(CmpConfigStepName.ADD_ONS, pathConfigWithIdAddOns),
        new CmpConfigStep(CmpConfigStepName.PARTNERS, pathConfigWithIdPartners),
        new CmpConfigStep(CmpConfigStepName.PURPOSES, pathConfigWithIdPurposes),
        new CmpConfigStep(CmpConfigStepName.INTEGRATION, pathConfigWithIdIntegration)
    );

    steps: CmpConfigStep[] = [];

    private constructor(...steps: CmpConfigStep[]) {
        this.steps = steps;
    }

    getStep(stepName: CmpConfigStepName): CmpConfigStep | undefined {
        return this.steps.find((it) => it.name === stepName);
    }

    getStepPosition(stepName: CmpConfigStepName | string): number {
        return this.steps.findIndex((it) => it.name === stepName) + 1;
    }

    getNextStep(stepName: CmpConfigStepName): CmpConfigStep | undefined {
        const stepIndex = this.steps.findIndex((it) => it.name === stepName);
        if (stepIndex >= 0 && stepIndex < this.steps.length - 1) {
            return this.steps[stepIndex + 1];
        }
    }

    getNextStepPath(currentStepName: CmpConfigStepName, cmpId: string): string {
        return this.getNextStep(currentStepName)?.getPath(cmpId) || pathSitesConfigurations;
    }

    getPreviousStep(stepName: CmpConfigStepName): CmpConfigStep | undefined {
        const stepIndex = this.steps.findIndex((it) => it.name === stepName);
        if (stepIndex > 0) {
            return this.steps[stepIndex - 1];
        }
    }

    getPreviousStepPath(currentStepName: CmpConfigStepName, cmpId: string): string {
        return this.getPreviousStep(currentStepName)?.getPath(cmpId) || pathSitesConfigurations;
    }
}

const CmpConfigStepperDelegated = CmpConfigStepper.DELEGATED;
export {CmpConfigStepperDelegated};

export default CmpConfigStepper.DEFAULT;
