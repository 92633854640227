import {
    Action,
    AlertSeverity,
    Box,
    BoxProps,
    BoxRadius,
    Button,
    ButtonStyle,
    Color,
    ContentBlock,
    ContentBlockRow,
    ContentBlockRowElementRight,
    Formatter,
    Loadable,
    MainContentPageHeader,
    SearchField,
    SearchToolbar,
    Select,
    SelectAutocomplete,
    Tag,
    TagSize,
    TagStyle
} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {buildAndDownloadCSV} from "../../../common/utils/portal";
import {ChartContainer, ChartLine} from "../../../component/widget";
import {CHART_DATASET_DEFAULT_STYLES, GraphData} from "../../../utils/stat/chart_options";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigFilter} from "../../../api/model/cmp/config/CmpConfigFilter";
import {CmpConfigSearchQuery} from "../../../api/model/cmp/config/CmpConfigSearchQuery";
import {handleCatchError} from "../../../common/utils/helper";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {SearchFieldPeriod} from "../../../component/snippet";
import {session} from "../../../api/ApiSession";
import {slugify} from "../../../common/utils/string";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

function DelegatedSitesAnalyticsHits() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [delegatedPartners, setDelegatedPartners] = useState<CmpConfigFilter<number>[]>([]);
    const [currentConfigId, setCurrentConfigId] = useState("");
    const [currentDelegatedPartnerId, setCurrentDelegatedPartnerId] = useState(0);

    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_HIT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_HIT_PERIOD.interval);
    const [hitGraphData, setHitGraphData] = useState<GraphData>();
    const [totalHits, setTotalHits] = useState(0);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const filters = await session.restCmpConfig.getFilters();
                setDelegatedPartners(filters.delegated_partners);

                const cmpConfigs = await session.restCmpConfig.list(new CmpConfigSearchQuery().withDelegatedMode());
                const cmpConfigsFiltered = cmpConfigs.filter((it) => it.step === "");
                setCmpConfigs(cmpConfigsFiltered);

                const firstDelegatedPartnerId = filters.delegated_partners[0].value || 0;
                setCurrentDelegatedPartnerId(firstDelegatedPartnerId);
                setCurrentConfigId(cmpConfigsFiltered.find((it) => it.id_partner === firstDelegatedPartnerId)?.id || "");
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load data", e.message));
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!currentConfigId || interval.isInvalid()) {
            return;
        }

        (async () => {
            try {
                setLoading(true);
                let start = period.interval.start;
                let end = period.interval.end;
                if (period === StatPeriod.CUSTOM) {
                    start = interval.start;
                    end = interval.end;
                }

                const hitStats = await session.restPartner.getDailyHits(start, end, currentConfigId, currentDelegatedPartnerId);
                setHitGraphData({
                    labels: hitStats.map((it) => it.date),
                    datasets: [{
                        label: textAnalytics("label.hits"),
                        data: hitStats.map((it) => it.hits),
                        ...CHART_DATASET_DEFAULT_STYLES
                    }]
                });
                setTotalHits(hitStats.reduce((total, {hits}) => total + hits, 0));
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
            } finally {
                setLoading(false);
            }
        })();
    }, [textAnalytics, period, interval, currentConfigId, currentDelegatedPartnerId]);

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_HIT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    const handleChangeDelegatedPartner = (partnerId: number) => {
        setCurrentDelegatedPartnerId(partnerId);
        setCurrentConfigId(cmpConfigs.find((it) => it.id_partner === partnerId)?.id || "");
    };

    const downloadCSVFile = () => {
        const currentConfig = cmpConfigs.find((config) => config.id === currentConfigId);

        if (!currentConfig) {
            return;
        }

        const fileTitle = textAnalytics("file.title", {name: slugify(`${currentConfig.delegated_partner_company}-${currentConfig.name}`)});
        const headers = [textAnalytics("file.column.date"), textAnalytics("file.column.hits")];
        let rows: string[][] = [];

        if (hitGraphData) {
            for (let i = 0; i < hitGraphData.labels.length; i++) {
                rows.push([hitGraphData.labels[i], hitGraphData.datasets[0].data[i].toString()]);
            }
        }

        buildAndDownloadCSV(fileTitle, headers, rows);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader
                    icon={{name: "bar_chart", colorIcon: Color.CYAN}}
                    title={{label: textAnalytics("hits.title"), tag: <Tag label={textConfiguration("delegation")} size={TagSize.XSMALL} style={TagStyle.DEFAULT_CYAN_LIGHT}/>}}
                />
                <SearchToolbar>
                    <SearchField label={textConfiguration(`field.${CmpConfigField.DELEGATED_PARTNER_COMPANY}`)}>
                        <Select
                            value={currentDelegatedPartnerId.toString()}
                            options={delegatedPartners.map((it) => ({
                                value: it.value.toString() || "",
                                label: it.label || "",
                                partnerId: it.value
                            }))}
                            onChange={(option) => option && handleChangeDelegatedPartner(+option.partnerId)}
                            disabled={delegatedPartners.length === 1}
                        />
                    </SearchField>
                    <SearchField label={textAnalytics("label.site")}>
                        <SelectAutocomplete
                            value={currentConfigId}
                            options={cmpConfigs.filter((it) => it.id_partner === currentDelegatedPartnerId).map(({id, name, domain}) => ({value: id, label: `${name} (${domain})`}))}
                            onChange={(option) => option && setCurrentConfigId(option.value as string)}
                        />
                    </SearchField>
                    <SearchFieldPeriod
                        period={period}
                        onChangePeriod={handleChangePeriod}
                        customInterval={interval}
                        onChangeCustomInterval={handleChangeInterval}
                    />
                </SearchToolbar>
                <Loadable loading={isLoading}>
                    <ContentBlock header={{title: {label: textAnalytics("hits.evolution_by_hits")}}} cssClass="analytics__hits">
                        <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD}>
                            <div className="analytics__hits__metric">
                                <span className="analytics__hits__metric__header">{textAnalytics("metric.hits_total")}</span>
                                <span className="analytics__hits__metric__value">{Formatter.formatNumber(totalHits)}</span>
                            </div>
                            <ChartContainer height="35rem">
                                <ChartLine data={hitGraphData}/>
                            </ChartContainer>
                        </Box>
                        <ContentBlockRow>
                            <ContentBlockRowElementRight>
                                <Button
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    icon={Action.EXPORT.icon}
                                    onClick={downloadCSVFile}
                                    children={textAnalytics("action.csv_export")}
                                />
                            </ContentBlockRowElementRight>
                        </ContentBlockRow>
                    </ContentBlock>
                </Loadable>
            </MainContent>
        </Wrapper>
    );
}

export default DelegatedSitesAnalyticsHits;
