export enum ConfigurationModeName {
    CUSTOM = "custom",
    FLASH = "flash",
    ADVANCED = "advanced"
}

export const CONFIGURATION_MODES: ConfigurationModeName[] = [
    ConfigurationModeName.CUSTOM,
    ConfigurationModeName.FLASH,
    ConfigurationModeName.ADVANCED
];

export const CONFIGURATION_MODES_DELEGATED: ConfigurationModeName[] = [
    ConfigurationModeName.FLASH,
    ConfigurationModeName.ADVANCED
];
