import {animateScroll as scroll} from "react-scroll";
import {AlertSeverity, ButtonLink, ButtonLinkStyle, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, LayoutRows, Loadable, MainContentPageHeader, SearchToolbar, Tabs, Tag, TagSize, TagStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {AnalyticsConsentDetailsDevice, AnalyticsConsentDetailsGlobal, SearchFieldPeriod} from "../../../component/snippet";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {handleCatchError} from "../../../common/utils/helper";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {pathDelegatedSitesAnalyticsConsent, TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {StatConsentDetails} from "../../../api/model/stat/consent/StatConsentDetails";
import {StatDetailsTab} from "../../../utils/stat/consent/StatDetailsTab";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {UIEventManager} from "../../../common/utils/UIEventManager";

function DelegatedSitesAnalyticsConsentDetails() {
    const navigate = useNavigate();
    const params = useParams() as {id: string};
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>();
    const [statsDetails, setStatsDetails] = useState<StatConsentDetails>(new StatConsentDetails());
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_CONSENT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_CONSENT_PERIOD.interval);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setCmpConfig(await session.restCmpConfig.get(params.id));
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load data", e.message));
            }
        })();
    }, [params.id]);

    useEffect(() => {
        if (!cmpConfig || interval.isInvalid()) {
            return;
        }

        (async () => {
            try {
                setLoading(true);
                const statsDetails = await session.restPartner.getConsentStatsForConfig(cmpConfig.id, period, interval.start, interval.end, undefined, cmpConfig.id_partner);
                setStatsDetails(statsDetails);
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
            } finally {
                setLoading(false);
            }
        })();
    }, [cmpConfig, interval, period, textAnalytics]);

    useEffect(() => {
        setInterval(period.interval);
    }, [period]);

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_CONSENT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    const handleClickDevice = (tab: StatDetailsTab) => {
        scroll.scrollToTop({delay: 0, duration: 0, smooth: "none"});
        setLoading(true);
        setTimeout(() => {
            setCurrentTabIndex(Object.values(StatDetailsTab).findIndex((it) => it === tab));
            setLoading(false);
        }, 100);
    };

    if (!cmpConfig) {
        return <></>;
    }

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.SMALL}>
                    <ButtonLink
                        style={ButtonLinkStyle.GREY}
                        icon={{name: "chevron_left"}}
                        reverse
                        reverseUnderline
                        onClick={() => navigate(pathDelegatedSitesAnalyticsConsent)}
                    >
                        {textAnalytics("action.back_to_list")}
                    </ButtonLink>
                    <MainContentPageHeader
                        icon={{name: "bar_chart", colorIcon: Color.CYAN}}
                        title={{label: `${cmpConfig.name} (${cmpConfig.domain})`, tag: <Tag label={cmpConfig?.delegated_partner_company || ""} size={TagSize.XSMALL} style={TagStyle.DEFAULT_CYAN_LIGHT}/>}}
                    />
                </FlexContent>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchFieldPeriod
                            period={period}
                            onChangePeriod={handleChangePeriod}
                            customInterval={interval}
                            onChangeCustomInterval={handleChangeInterval}
                        />
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        <Tabs
                            headers={Object.values(StatDetailsTab).map((it) => ({label: textAnalytics(`tab.${it}`)}))}
                            current={currentTabIndex}
                        >
                            <AnalyticsConsentDetailsGlobal
                                statsDetails={statsDetails}
                                period={period}
                                onClickDevice={handleClickDevice}
                            />
                            <AnalyticsConsentDetailsDevice
                                dates={statsDetails.dates}
                                deviceRates={statsDetails.desktop}
                                period={period}
                            />
                            <AnalyticsConsentDetailsDevice
                                dates={statsDetails.dates}
                                deviceRates={statsDetails.mobile}
                                period={period}
                            />
                            <AnalyticsConsentDetailsDevice
                                dates={statsDetails.dates}
                                deviceRates={statsDetails.tablet}
                                period={period}
                            />
                        </Tabs>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DelegatedSitesAnalyticsConsentDetails;
