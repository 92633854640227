import {
    AlertSeverity,
    Button,
    ButtonSize,
    Color,
    ContentBlock,
    EmptyContentMessage,
    LayoutRows,
    MainContentPageHeader,
    SearchError,
    SearchToolbar,
    SvgUnderConstruction,
    Table,
    TableColumnStyle
} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {CmpAnalyticsRow, CrossSales, SearchFieldPeriod} from "../../../component/snippet";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CrossSalesProduct} from "../../../utils/CrossSalesProduct";
import {handleCatchError} from "../../../common/utils/helper";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {PAGE_SIZE, pathSitesConfigurations, TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {StatConsentRatingsResult} from "../../../api/model/stat/consent/StatConsentRatingsResult";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {UIEventManager} from "../../../common/utils/UIEventManager";

function SitesAnalyticsConsent() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);

    const [query, setQuery] = useState<string>("");
    const [currentCmpConfigs, setCurrentCmpConfigs] = useState<CmpConfig[]>([]);
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_CONSENT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_CONSENT_PERIOD.interval);

    const [statConsentRatings, setStatConsentRatings] = useState<StatConsentRatingsResult>(new StatConsentRatingsResult());

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_CONSENT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    useEffect(() => {
        const newCurrentCmpConfigs = cmpConfigs.filter((item) => !query || `${item.name} ${item.domain}`.toLowerCase().includes(query.toLowerCase()));
        setCurrentCmpConfigs(newCurrentCmpConfigs);
    }, [query, cmpConfigs]);

    useEffect(() => {
        setInterval(period.interval);
    }, [period]);

    useEffect(() => {
        if (!cmpConfigs.length || interval.isInvalid()) {
            setStatConsentRatings(new StatConsentRatingsResult());
            return;
        }

        (async () => {
            setLoading(true);

            try {
                const statRatings = await session.restPartner.getConsentStatsRatings(period, interval.start, interval.end);
                setStatConsentRatings(statRatings);
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
                setStatConsentRatings(new StatConsentRatingsResult());
            } finally {
                setLoading(false);
            }
        })();
    }, [cmpConfigs, period, interval, textAnalytics]);

    useEffect(() => {
        (async () => {
            try {
                const cmpConfigs = await session.restCmpConfig.list();
                setCmpConfigs(cmpConfigs);
                setCurrentCmpConfigs(cmpConfigs);
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load data", e.message));
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textAnalytics("consent.title")} icon={{name: "bar_chart", colorIcon: Color.CYAN}}/>
                <LayoutRows>
                    {!!cmpConfigs.length ? (
                        <ContentBlock>
                            <SearchToolbar searchBar={{placeholder: textConfiguration("search.site"), value: query, onChange: setQuery}} canHideFilters>
                                <SearchFieldPeriod
                                    period={period}
                                    onChangePeriod={handleChangePeriod}
                                    customInterval={interval}
                                    onChangeCustomInterval={handleChangeInterval}
                                />
                            </SearchToolbar>
                            {!!currentCmpConfigs.length ? (
                                <Table
                                    columns={[
                                        {width: 10, label: textConfiguration(`field.${CmpConfigField.ID}`)},
                                        {width: 40, label: textConfiguration(`field.${CmpConfigField.NAME}`)},
                                        {width: 15, label: textAnalytics("metric.consent"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAnalytics("metric.object"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAnalytics("metric.no_choice"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 5, styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={PAGE_SIZE}
                                >
                                    {currentCmpConfigs.map((config) => (
                                        <CmpAnalyticsRow
                                            key={config.id}
                                            config={config}
                                            statConsentRatings={statConsentRatings}
                                            isLoading={isLoading}
                                        />
                                    ))}
                                </Table>
                            ) : (
                                <SearchError query={query}/>
                            )}
                        </ContentBlock>
                    ) : (
                        <EmptyContentMessage svg={SvgUnderConstruction} message={textAnalytics("empty_content.message")}>
                            <Button onClick={() => navigate(pathSitesConfigurations)} size={ButtonSize.BIG}>
                                {textAnalytics("empty_content.start")}
                            </Button>
                        </EmptyContentMessage>
                    )}
                    <CrossSales product={CrossSalesProduct.GTM_SERVER_SIDE}/>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default SitesAnalyticsConsent;
