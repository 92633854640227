import {
    AlertSeverity,
    Color,
    ContentBlock,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    Table,
    TableColumnStyle,
    Tag,
    TagSize,
    TagStyle
} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {CmpAnalyticsRow, SearchFieldPeriod} from "../../../component/snippet";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigFilters} from "../../../api/model/cmp/config/CmpConfigFilters";
import {CmpConfigSearchField, CmpConfigSearchQuery} from "../../../api/model/cmp/config/CmpConfigSearchQuery";
import {handleCatchError} from "../../../common/utils/helper";
import {MainContent, Wrapper} from "../../../common/component/widget";
import {MainHeader} from "../../../common/component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {StatConsentRatingsResult} from "../../../api/model/stat/consent/StatConsentRatingsResult";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {StatPeriodInterval} from "../../../utils/stat/StatPeriodInterval";
import {UIEventManager} from "../../../common/utils/UIEventManager";

function DelegatedSitesAnalyticsConsent() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentCmpConfigs, setCurrentCmpConfigs] = useState<CmpConfig[]>([]);
    const [statConsentRatings, setStatConsentRatings] = useState<StatConsentRatingsResult>(new StatConsentRatingsResult());

    const [filters, setFilters] = useState<CmpConfigFilters>(new CmpConfigFilters());
    const [searchQuery, setSearchQuery] = useState<CmpConfigSearchQuery>(new CmpConfigSearchQuery().withDelegatedMode());
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.DEFAULT_CONSENT_PERIOD);
    const [interval, setInterval] = useState<StatPeriodInterval>(StatPeriod.DEFAULT_CONSENT_PERIOD.interval);

    const [isLoading, setLoading] = useState(true);
    const [isLoadingStatRatings, setLoadingStatRatings] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setFilters(await session.restCmpConfig.getFilters());
            } catch (e) {
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                let cmpConfigs = await session.restCmpConfig.list(searchQuery);
                setCmpConfigs(cmpConfigs.filter((it) => it.step === ""));
                setCurrentCmpConfigs(cmpConfigs);
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load data", e.message));
            } finally {
                setLoading(false);
            }
        })();
    }, [searchQuery]);

    useEffect(() => {
        if (!filters.delegated_partners.length || interval.isInvalid()) {
            setStatConsentRatings(new StatConsentRatingsResult());
            return;
        }

        (async () => {
            try {
                setLoadingStatRatings(true);
                for (const delegatedPartner of filters.delegated_partners) {
                    const delegatedPartnerStatRatings = await session.restPartner.getConsentStatsRatings(period, interval.start, interval.end, undefined, delegatedPartner.value);
                    setStatConsentRatings((prevState) => ({...prevState, ...delegatedPartnerStatRatings}));
                }
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
                setStatConsentRatings(new StatConsentRatingsResult());
            } finally {
                setLoadingStatRatings(false);
            }
        })();
    }, [filters.delegated_partners, period, interval, textAnalytics]);

    useEffect(() => {
        const newCurrentCmpConfigs = cmpConfigs.filter((item) => !searchQuery.query || `${item.name} ${item.domain}`.toLowerCase().includes(searchQuery.query.toLowerCase()));
        setCurrentCmpConfigs(newCurrentCmpConfigs);
    }, [cmpConfigs, searchQuery]);

    useEffect(() => {
        setInterval(period.interval);
    }, [period]);

    const handleChangeSearchQuery = (field: CmpConfigSearchField, value: any) => {
        setSearchQuery((prevState) => new CmpConfigSearchQuery({...prevState, [field]: value}));
    };

    const handleChangePeriod = (period?: StatPeriod) => {
        setPeriod(period || StatPeriod.DEFAULT_CONSENT_PERIOD);
    };

    const handleChangeInterval = (newInterval: StatPeriodInterval) => {
        setInterval(newInterval);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader
                    icon={{name: "bar_chart", colorIcon: Color.CYAN}}
                    title={{label: textAnalytics("consent.title"), tag: <Tag label={textConfiguration("delegation")} size={TagSize.XSMALL} style={TagStyle.DEFAULT_CYAN_LIGHT}/>}}
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock>
                            <SearchToolbar
                                searchBar={{
                                    placeholder: textConfiguration("search.site"),
                                    value: searchQuery.query,
                                    onChange: (value) => handleChangeSearchQuery(CmpConfigSearchField.QUERY, value)
                                }}
                                canHideFilters
                            >
                                <SearchField label={textConfiguration(`field.${CmpConfigField.DELEGATED_PARTNER_COMPANY}`)}>
                                    <Select
                                        value={searchQuery.partner_id?.toString() || ""}
                                        options={[
                                            {value: "", label: textConfiguration("option.ALL")},
                                            ...filters.delegated_partners.map((it) => ({
                                                value: it.value.toString() || "",
                                                label: it.label || "",
                                                partnerId: it.value
                                            }))
                                        ]}
                                        onChange={(option) => option && handleChangeSearchQuery(CmpConfigSearchField.PARTNER_ID, option.partnerId)}
                                    />
                                </SearchField>
                                <SearchFieldPeriod
                                    period={period}
                                    onChangePeriod={handleChangePeriod}
                                    customInterval={interval}
                                    onChangeCustomInterval={handleChangeInterval}
                                />
                            </SearchToolbar>
                            {!!currentCmpConfigs.length ? (
                                <Table
                                    columns={[
                                        {width: 10, label: textConfiguration(`field.${CmpConfigField.ID}`)},
                                        {width: 20, label: textConfiguration(`field.${CmpConfigField.NAME}`)},
                                        {width: 20, label: textConfiguration(`field.${CmpConfigField.DELEGATED_PARTNER_COMPANY}`)},
                                        {width: 15, label: textAnalytics("metric.consent"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAnalytics("metric.object"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAnalytics("metric.no_choice"), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 5, styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={PAGE_SIZE}
                                >
                                    {currentCmpConfigs.map((config) => (
                                        <CmpAnalyticsRow
                                            key={config.id}
                                            config={config}
                                            statConsentRatings={statConsentRatings}
                                            isLoading={isLoadingStatRatings}
                                        />
                                    ))}
                                </Table>
                            ) : (
                                <SearchError query={searchQuery.query}/>
                            )}
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DelegatedSitesAnalyticsConsent;
