import {Locale} from "../common/utils/Locale";
import {usePreprod} from "../common/utils/helper";

export const sirdataCmpPricingUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/CMP-Tarifs" : "https://www.sirdata.com/en/CMP-Pricing";
export const sirdataDocumentationUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/" : "https://cmp.docs.sirdata.net/v/en/";
export const sirdataDocumentationTagManagementUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/gestion-des-scripts/exemples" : "https://cmp.docs.sirdata.net/v/en/script-management/examples";
export const sirdataFaqUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/faq" : "https://cmp.docs.sirdata.net/v/en/faq";

export const googleConsentModeUrl = `https://support.google.com/analytics/answer/9976101?hl=${Locale.isFrench() ? "fr" : "en"}`;
export const googleAnalyticsCnilWarningUrl = Locale.isFrench() ? "https://www.cnil.fr/fr/utilisation-de-google-analytics-et-transferts-de-donnees-vers-les-etats-unis-la-cnil-met-en-demeure" : "https://www.cnil.fr/en/use-google-analytics-and-data-transfers-united-states-cnil-orders-website-manageroperator-comply";
export const googleAdManagerDocUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/gestion-des-scripts/exemples#google-ad-manager" : "https://cmp.docs.sirdata.net/v/en/script-management/examples#google-ad-manager";
export const microsoftConsentModeUrl = `https://help.ads.microsoft.com/#apex/ads/${Locale.isFrench() ? "fr" : "en"}/consent%20mode`;

export const defaultSkinImageUrl = usePreprod ? "https://dev.cdn.abconsent.net/cmp/images/theme/skin/1-cookies.png" : "https://cdn.abconsent.net/cmp/images/theme/skin/1-cookies.png";
export const defaultWatermarkImageUrl = usePreprod ? "https://dev.cdn.abconsent.net/cmp/images/theme/watermark/1-cookie.png" : "https://cdn.abconsent.net/cmp/images/theme/watermark/1-cookie.png";

export const complianceAssistanceDocumentationSrc = Locale.isFrench() ? "/resources/Aide-a-la-demonstration-de-la-conformite-du-consentement-receuilli-par-la-CMP-de-Sirdata.pdf" : "/resources/Aide-a-la-demonstration-de-la-conformite-du-consentement-receuilli-par-la-CMP-de-Sirdata.pdf";
