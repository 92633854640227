import {IconTooltip, Loadable, TableActionColumn, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AnalyticsColor} from "../../utils/AnalyticsColor";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {pathDelegatedSitesAnalyticsConsentWithId, pathSitesAnalyticsConsentWithId, TranslationPortalFile} from "../../utils/constants";
import {PathHelper} from "../../common/utils/PathHelper";
import {StatConsentRatingsResult} from "../../api/model/stat/consent/StatConsentRatingsResult";

type CmpAnalyticsRowProps = {
    config: CmpConfig;
    statConsentRatings: StatConsentRatingsResult;
    isLoading: boolean;
};

const CmpAnalyticsRow: FunctionComponent<CmpAnalyticsRowProps> = ({config, statConsentRatings, isLoading}) => {
    const navigate = useNavigate();
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);

    const isDelegatedConfig = !!config.delegated_partner_company;

    return (
        <TableRow key={config.id}>
            <TableColumn>
                <span>{config.id}</span>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                <span>{config.name}</span>
            </TableColumn>
            {isDelegatedConfig &&
                <TableColumn>
                    <span>{config.delegated_partner_company}</span>
                </TableColumn>
            }
            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]}>
                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                    <span style={{color: AnalyticsColor.CONSENT}}>{`${statConsentRatings[config.id]?.consent || 0}%`}</span>
                </Loadable>
            </TableColumn>
            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]}>
                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                    <span style={{color: AnalyticsColor.OBJECT}}>{`${statConsentRatings[config.id]?.object || 0}%`}</span>
                </Loadable>
            </TableColumn>
            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_SMALL]}>
                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                    <span>{`${statConsentRatings[config.id]?.no_choice || 0}%`}</span>
                </Loadable>
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <IconTooltip
                    icon={{name: "visibility"}}
                    text={textAnalytics("action.see_full_analytics")}
                    onClick={() => navigate(PathHelper.buildPathWithId(isDelegatedConfig ? pathDelegatedSitesAnalyticsConsentWithId : pathSitesAnalyticsConsentWithId, config.id))}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default CmpAnalyticsRow;
