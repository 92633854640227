import {MailRequest} from "../../../common/api/model/mailing/MailRequest";

export class CustomCmpRequestMailRequest extends MailRequest {
    readonly template = "cmp-custom-request-notification";

    constructor(siteName: string) {
        super();
        this.values = {
            "site_name": siteName
        };
    }
}
