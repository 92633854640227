import {
    pathDelegatedSites,
    pathDelegatedSitesAnalyticsConsent,
    pathDelegatedSitesAnalyticsHits,
    pathDelegatedSitesConfigurations,
    pathDocumentation,
    pathSites,
    pathSitesAnalyticsConsent,
    pathSitesAnalyticsHits,
    pathSitesConfigurations
} from "./constants";
import {sirdataFaqUrl} from "./helper";
import {pathHome} from "../common/utils/constants";
import {MenuItem} from "@sirdata/ui-lib";
import {Authorization} from "../api/model/account/Authorization";

export enum Menu {
    HOME = "home",
    SITES = "sites",
    SITES_CONFIGURATIONS = "sites_configurations",
    SITES_ANALYTICS_CONSENT = "sites_analytics_consent",
    SITES_ANALYTICS_HITS = "sites_analytics_hits",
    DELEGATED_SITES = "delegated_sites",
    DELEGATED_SITES_CONFIGURATIONS = "delegated_sites_configurations",
    DELEGATED_SITES_ANALYTICS_CONSENT = "delegated_sites_analytics_consent",
    DELEGATED_SITES_ANALYTICS_HITS = "delegated_sites_analytics_hits",
    DOCUMENTATION = "documentation",
    FAQ = "faq"
}

export const MENU: MenuItem[] = [
    {
        label: Menu.HOME,
        path: pathHome,
        icon: {name: "home"}
    },
    {
        label: Menu.SITES,
        path: pathSites,
        icon: {name: "verified_user", outlined: true},
        items: [
            {
                label: Menu.SITES_CONFIGURATIONS,
                path: pathSitesConfigurations,
                icon: {name: "settings"}
            },
            {
                label: Menu.SITES_ANALYTICS_CONSENT,
                path: pathSitesAnalyticsConsent,
                icon: {name: "bar_chart"}
            },
            {
                label:
                Menu.SITES_ANALYTICS_HITS,
                path: pathSitesAnalyticsHits,
                icon: {name: "bar_chart"}
            }
        ]
    },
    {
        label: Menu.DELEGATED_SITES,
        path: pathDelegatedSites,
        icon: {name: "display_settings", outlined: true},
        allowedTo: Authorization.DELEGATION,
        items: [
            {
                label: Menu.DELEGATED_SITES_CONFIGURATIONS,
                path: pathDelegatedSitesConfigurations,
                icon: {name: "settings"}
            },
            {
                label: Menu.DELEGATED_SITES_ANALYTICS_CONSENT,
                path: pathDelegatedSitesAnalyticsConsent,
                icon: {name: "bar_chart"}
            },
            {
                label: Menu.DELEGATED_SITES_ANALYTICS_HITS,
                path: pathDelegatedSitesAnalyticsHits,
                icon: {name: "bar_chart"}
            }
        ]
    },
    {
        label: Menu.DOCUMENTATION,
        path: pathDocumentation,
        icon: {name: "description"}
    },
    {
        label: Menu.FAQ,
        path: sirdataFaqUrl,
        icon: {name: "forum"},
        externalUrl: true
    }
];
